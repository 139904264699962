import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { matchPath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Alert,
} from 'reactstrap';
import { css } from 'emotion';
import { selectWeaveUserAcls } from '../../../redux/actions/auth/auth.selectors';
import * as Paths from '../../../constants/paths';
import {
  selectCurrentLocationBannerMessages,
  selectCurrentLocationId,
  selectCurrentLocationParentId,
  selectIsCurrentLocationActive,
  selectIsMultiLocationBeta,
} from '../../../redux/actions/location';
import { selectWamSyncStatus } from '../../../redux/actions/customization/customization.action';
import { WamSearch } from '../../header-container/wam-search/wam-search';
import { theme } from '@weave/theme-original';
import { CaretUpIcon, HamburgerMenuIcon, IconButton } from '@weave/design-system';
import { selectHasBillingReadPermission } from '../../settings/billing/billing.helpers';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';
import {
  selectFeatureFlag,
  selectHasFeatureFlagEnabled,
} from '../../../redux/actions/feature-flags';
import { Store } from '../../../redux/store/store.model';
import { WamSyncAlert } from '../../wam-sync-alert/wam-sync-alert.component';
import { WamSyncFeatureFlags } from '../../customization-page-container/customization.constant';

const dropDownContainer = css`
  margin: ${theme.spacing(1)};
  width: max-content;
  .dropdown-menu {
    button {
      :hover {
        background-color: ${theme.colors.gray300};
      }
    }
  }
  > div {
    background-color: none;
    margin: ${theme.spacing(0.5)};
    > button {
      color: ${theme.colors.white};
      border: transparent;
      font-weight: bold;
      border-radius: 30px;
      background-color: ${theme.colors.weaveBlue};
      :hover {
        background-color: ${theme.colors.hoverBlue};
      }
      :focus {
        background-color: ${theme.colors.weaveBlue};
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    width: 45%;
    > div {
      justify-content: center;
      background-color: none;
      margin: ${theme.spacing(0.5)};
      width: 100%;
      > button {
        width: 100%;
      }
    }
  }
`;

const freeLinkContainer = css`
  margin: ${theme.spacing(1, 4)};
  display: flex;
  justify-content: space-between;
  width: max-content;
  a {
    margin: ${theme.spacing(1)};
    text-decoration: none;
    color: ${theme.colors.weaveBlue};
    font-weight: bold;
    text-align: center;
  }
  @media only screen and (max-width: 1000px) {
    width: 45%;
    li {
      width: 100%;
      white-space: nowrap;
    }
  }
`;

const navigationContainer = (displayNav) => css`
  @media only screen and (min-width: 1000px) {
    display: block;
  }
  @media only screen and (max-width: 1000px) {
    display: flex;
    ${!displayNav && `display: none;`}
  }
`;

const searchContainer = css`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
  @media only screen and (min-width: 1000px) {
    > button {
      display: none;
    }
  }
`;

const menuWrapper = css`
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.heavy};
  margin: ${theme.spacing(0, 2, 2, 2)};
  padding: ${theme.spacing(2, 3)};
  label: menuWrapper;
`;

const alertWrapper = css`
  margin: 10px 15px;
  border-radius: 10px;
`;

const alertStyles = css`
  border-radius: 10px;
`;

export const Navigation = () => {
  const history = useHistory();
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [phoneSystemOpen, setPhoneSystemOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [syncAppOpen, setSyncAppOpen] = useState<boolean>(false);
  const [analyticsOpen, setAnalyticsOpen] = useState<boolean>(false);
  const [onboardingOpen, setOnboardingOpen] = useState<boolean>(false);
  const [displayNav, setDisplayNav] = useState<boolean>(true);
  const pathname = history.location.pathname;
  const locationId = useSelector(selectCurrentLocationId);
  const isLocationActive = useSelector(selectIsCurrentLocationActive);
  const isParentLocation = !useSelector(selectCurrentLocationParentId);
  const isMultiOfficeBeta = useSelector(selectIsMultiLocationBeta);
  const bannerMessages = useSelector(selectCurrentLocationBannerMessages);
  const wamSyncStatus = useSelector(selectWamSyncStatus);
  const userAcls = useSelector(selectWeaveUserAcls);
  const showOutOfSyncBanner = useSelector((state) =>
    selectFeatureFlag(state, WamSyncFeatureFlags.SHOW_OUT_OF_SYNC_ALERT)
  );
  const syncAppInstallAdvancedACL =
    userAcls?.indexOf(CoreACLs.SYNCAPPINSTALLADVANCED) >= 0;

  const newAutoMessagingFlag = useSelector((state: Store) =>
    selectHasFeatureFlagEnabled(state, 'commx-reminders-v2')
  );

  const userCanReadBilling = useSelector(selectHasBillingReadPermission);

  const getPath = (path: string) => Paths.injectParams(path, { id: locationId });
  const renderDropdownItem = (to: string, text: string) => {
    const active = !!matchPath(pathname, { path: to });
    return (
      <DropdownItem active={active} onClick={() => history.push(getPath(to))}>
        <NavItem>
          <span
            className={css`
              font-size: 1.125rem;
            `}
          >
            {text}
          </span>
        </NavItem>
      </DropdownItem>
    );
  };

  if (!locationId) {
    return null;
  }

  return (
    <div className="navigation-wrapper">
      {!isLocationActive && (
        <div className={alertWrapper}>
          <Alert color="danger" className={alertStyles}>
            <span>This office is inactive</span>
          </Alert>
        </div>
      )}
      {showOutOfSyncBanner && (
        <div className={alertWrapper}>
          <WamSyncAlert wamSyncStatus={wamSyncStatus} locationId={locationId} />
        </div>
      )}
      {isMultiOfficeBeta && (
        <div className={alertWrapper}>
          <Alert color="warning" className={alertStyles}>
            <span>
              This office is configured using beta-features DataAccessRules and/or
              Parent/Child relationships; please redirect any issues or questions to a
              multi-office specialist or use "@multiofficebeta" in Slack.
            </span>
          </Alert>
        </div>
      )}
      {bannerMessages?.map((msg, index) => {
        return (
          <div key={index} className={alertWrapper}>
            <Alert color="warning" className={alertStyles}>
              <span>{msg}</span>
            </Alert>
          </div>
        );
      })}
      <div className={menuWrapper}>
        <div className={searchContainer}>
          <WamSearch />
          <IconButton label="Expand" onClick={() => setDisplayNav(!displayNav)}>
            {displayNav ? <CaretUpIcon /> : <HamburgerMenuIcon />}
          </IconButton>
        </div>
        <div className={navigationContainer(displayNav)}>
          <Nav className={dropDownContainer}>
            <Dropdown
              isOpen={phoneSystemOpen}
              toggle={() => setPhoneSystemOpen(!phoneSystemOpen)}
            >
              <DropdownToggle caret>Phone System</DropdownToggle>
              <DropdownMenu>
                {renderDropdownItem(Paths.PHONES.callBlocking, 'Call Blocking')}
                {renderDropdownItem(Paths.PHONES.callGroups, 'Call Groups')}
                {renderDropdownItem(Paths.PHONES.callSettings, 'Call Settings')}
                {renderDropdownItem(Paths.PHONES.devices, 'Devices')}
                {renderDropdownItem(Paths.PHONES.faxSettings, 'Fax Settings')}
                {renderDropdownItem(Paths.PHONES.holdMusic, 'Hold Music')}
                {renderDropdownItem(Paths.PHONES.mediaLibrary, 'Media Library')}
                {/* {renderDropdownItem(Paths.PHONES.tenantMigration, 'Merge to Parent Data')} */}
                {renderDropdownItem(Paths.PHONES.officeHours, 'Office Hours')}
                {renderDropdownItem(Paths.PHONES.phoneTree, 'Phone Tree')}
                {renderDropdownItem(Paths.PHONES.orderNewNmber, 'Order New Number')}
                {renderDropdownItem(Paths.PHONES.voiceMailboxes, 'Voice Mailboxes')}
              </DropdownMenu>
            </Dropdown>

            <Dropdown isOpen={historyOpen} toggle={() => setHistoryOpen(!historyOpen)}>
              <DropdownToggle caret>History</DropdownToggle>
              <DropdownMenu>
                {renderDropdownItem(Paths.HISTORY.appointmentHistory, 'Appointments')}
                {renderDropdownItem(Paths.HISTORY.autoMsgQueue, 'Auto Message Queue')}
                {renderDropdownItem(Paths.HISTORY.callRecords, 'Call Records')}
                {renderDropdownItem(Paths.HISTORY.clientLocations, 'Client Locations')}
                {renderDropdownItem(Paths.HISTORY.emailHistory, 'Email')}
                {renderDropdownItem(Paths.HISTORY.logs, 'Logs')}
                {renderDropdownItem(Paths.HISTORY.orderEvents, 'Order Events')}
                {renderDropdownItem(Paths.HISTORY.patients, 'Patients')}
                {renderDropdownItem(Paths.HISTORY.patientDetails, 'Patient Details')}
                {renderDropdownItem(Paths.HISTORY.recallEvents, 'Recall Events')}
                {renderDropdownItem(Paths.HISTORY.reviewsList, 'Reviews')}
                {renderDropdownItem(Paths.HISTORY.smsHistory, 'SMS')}
                {renderDropdownItem(
                  Paths.HISTORY.appointmentConfirmationWritebacks,
                  'Appointment Confirmation Writebacks'
                )}
                {renderDropdownItem(
                  Paths.HISTORY.syncAppWritebacks,
                  'SyncApp Writebacks'
                )}
              </DropdownMenu>
            </Dropdown>
            <Dropdown isOpen={settingsOpen} toggle={() => setSettingsOpen(!settingsOpen)}>
              <DropdownToggle caret>Settings</DropdownToggle>
              <DropdownMenu>
                {renderDropdownItem(Paths.SETTINGS.tendlc, '10DLC')}
                {renderDropdownItem(Paths.SETTINGS.autoReminders, 'Auto Reminders')}
                {userCanReadBilling &&
                  renderDropdownItem(Paths.SETTINGS.billing, 'Billing')}
                {renderDropdownItem(Paths.SETTINGS.emailMarketing, 'Email Marketing')}
                {renderDropdownItem(Paths.SETTINGS.featureFlags, 'Feature Flags')}
                {renderDropdownItem(Paths.SETTINGS.locations, 'Locations')}
                {renderDropdownItem(Paths.SETTINGS.manualTemplates, 'Manual Templates')}
                {renderDropdownItem(Paths.SETTINGS.onlineScheduling, 'Online Scheduling')}
                {renderDropdownItem(Paths.SETTINGS.payments, 'Payments')}
                {renderDropdownItem(Paths.SETTINGS.reviews, 'Reviews')}
                {renderDropdownItem(Paths.SETTINGS.statusMapping, 'Status Mapping')}
              </DropdownMenu>
            </Dropdown>

            {isParentLocation && (
              <Dropdown isOpen={syncAppOpen} toggle={() => setSyncAppOpen(!syncAppOpen)}>
                <DropdownToggle caret>Sync App</DropdownToggle>
                <DropdownMenu>
                  {renderDropdownItem(Paths.SYNC_APP.syncApps, 'Sync Apps')}
                  {isMultiOfficeBeta &&
                    renderDropdownItem(
                      Paths.SYNC_APP.multiSyncAppSettings,
                      'Multi Location Data Settings'
                    )}
                  {syncAppInstallAdvancedACL &&
                    renderDropdownItem(
                      Paths.SYNC_APP.syncAppSettings,
                      'Sync App Settings'
                    )}
                </DropdownMenu>
              </Dropdown>
            )}
            <Dropdown
              isOpen={analyticsOpen}
              toggle={() => setAnalyticsOpen(!analyticsOpen)}
            >
              <DropdownToggle caret>Analytics</DropdownToggle>
              <DropdownMenu>
                {renderDropdownItem(Paths.ANALYTICS.roiScorecard, 'ROI Scorecard')}
                {renderDropdownItem(Paths.ANALYTICS.settings, 'Settings')}
                {/* {renderDropdownItem(Paths.ANALYTICS.onboarding, 'Onboarding')} */}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={onboardingOpen}
              toggle={() => setOnboardingOpen(!onboardingOpen)}
            >
              <DropdownToggle data-testid="onboarding-dropdown" caret>
                Onboarding
              </DropdownToggle>
              <DropdownMenu>
                {renderDropdownItem(Paths.ONBOARDING.handoff, 'Handoff Hub')}
                {renderDropdownItem(Paths.ONBOARDING.onboarding, 'Onboarding')}
                {renderDropdownItem(Paths.ONBOARDING.intakeForm, 'Intake Form')}
                {renderDropdownItem(Paths.ONBOARDING.portingV2, 'Porting')}
              </DropdownMenu>
            </Dropdown>
          </Nav>

          <Nav className={freeLinkContainer}>
            <NavItem>
              <NavLink to={getPath(Paths.OTHER.dashboard)} activeClassName="is-active">
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to={getPath(Paths.OTHER.users)} activeClassName="is-active">
                Users
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={getPath(Paths.OTHER.desktopClients)}
                activeClassName="is-active"
              >
                Desktop Clients
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={getPath(Paths.OTHER.customization)}
                activeClassName="is-active"
              >
                Customization
              </NavLink>
            </NavItem>
            {/*<NavItem>*/}
            {/*  <NavLink to={getPath(Paths.OTHER.onboarding)} activeClassName="is-active">*/}
            {/*    Onboarding*/}
            {/*  </NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
              <NavLink to={getPath(Paths.OTHER.auditLogs)} activeClassName="is-active">
                Audit Logs
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  );
};
