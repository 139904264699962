import React, { useEffect, useState } from 'react';
import { useRequestState, useAlert } from '@weave/alert-system';
import {
  ButtonBar,
  ContentLoader,
  EmailField,
  FormRow,
  Heading,
  MultiselectField,
  PasswordField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useForm,
  Text,
  SwitchField,
  ValidatorFieldState,
} from '@weave/design-system';
import {
  selectAvailableRoles,
  selectUserFound,
} from '../../redux/actions/users/user-roles.action';
import { UserModel } from '../../models/user.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  hasMobileAccess,
  formatAvailableOptions,
  removeDenyMobileAccessRole,
  addDenyMobileAccessRole,
  handleFormatSelectedOptions,
} from './users.helpers';
import {
  saveUserProfile,
  sendPasswordReset,
  addNewUser,
} from '../../redux/actions/users';
import { UserSearchContainer } from './user-search.container';
import isEmpty from 'lodash/isEmpty';
import { useResource } from '../shared/hooks';
import * as styles from './user-form.styles';
import { usersApi } from '../../redux/actions/users/users.api';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';

type Props = {
  searchedEmail: string;
  selectedUser: UserModel;
  handleEditClose: () => void;
};

export const UserEditForm = ({ selectedUser, searchedEmail, handleEditClose }: Props) => {
  const availableRoles = useSelector(selectAvailableRoles);
  const userFound = useSelector(selectUserFound);
  const { loading } = useRequestState(saveUserProfile.toString());
  const [roleOptions] = useState<string[]>(availableRoles.map((role) => role.Name));
  const [creatingUser, setCreatingUser] = useState(false);
  const dispatch = useDispatch();
  const alerts = useAlert();

  useEffect(() => {
    if (selectedUser.UserID) {
      loadData(selectedUser);
    }
    return () => {
      reset();
      setCreatingUser(false);
    };
  }, [selectedUser]);

  const setupForm = (user: UserModel) => {
    const startingValues = {
      Username: user.Username || searchedEmail || '',
      FirstName: user.FirstName || '',
      LastName: user.LastName || '',
      Roles: user.Roles ? [...user?.Roles.map((role) => role.Name)] : [],
      Password: '',
      hasMobileAccess: hasMobileAccess(user.Roles),
      TicketID: user.TicketID || '',
    };
    seedValues(startingValues);
  };

  const loadData = async (user: UserModel) => {
    if (userFound) {
      setupForm(user);
    } else {
      try {
        const updatedUser = await usersApi.getJobTitles(user);
        setupForm(updatedUser);
      } catch (err: any) {
        alerts.error(err?.message ? err.message : "couldn't retrieve user job titles.");
      }
    }
  };

  const handleSubmit = (formValues) => {
    if (creatingUser && formValues.Password.length < 1) {
      alerts.error('Password is a required field.');
      return;
    }
    formValues.Roles = handleFormatSelectedOptions(formValues.Roles, availableRoles);

    /* if roles haven't changed send roles without formatting */
    if (!formValues.Roles[0].hasOwnProperty('ID')) {
      formValues.Roles = selectedUser.Roles;
    }

    if (formValues.hasMobileAccess) {
      formValues.Roles = removeDenyMobileAccessRole(formValues.Roles);
    } else {
      // no mobileAccess
      formValues.Roles = addDenyMobileAccessRole(formValues.Roles);
    }
    const user: UserModel = {
      ...formValues,
      UserID: selectedUser.UserID,
      formattedAvailableRoles: formatAvailableOptions(availableRoles),
    };

    const userPayload = {
      user: user,
      addingNewUser: userFound,
    };

    if (userFound || !isEmpty(selectedUser)) {
      dispatch(saveUserProfile(userPayload));
    } else {
      dispatch(addNewUser(user));
    }
    handleEditClose();
  };

  const { formProps, getFieldProps, seedValues, reset, isComplete } = useForm({
    fields: {
      Username: {
        type: 'email',
        required: true,
        validator: ({ value }: ValidatorFieldState<'email'>) =>
          value.includes('getweave.com') ? 'Can not use a getweave.com email.' : '',
      },
      FirstName: { type: 'text', required: true },
      LastName: { type: 'text', required: true },
      Roles: {
        type: 'multiselect',
        minRequired: 1,
        value: [],
      },
      Password: { type: 'password', minChars: 1, required: creatingUser },
      hasMobileAccess: { type: 'switch', value: true },
      TicketID: { type: 'text', required: true },
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <ContentLoader show={loading} />
      {isEmpty(selectedUser) && !creatingUser && (
        <div className="form-section">
          <Text>Find a user by email address:</Text>
          <UserSearchContainer />
          <div className="create-new">
            <label>Or create a new user to add to this location.</label>
            <PrimaryButton className="create" onClick={() => setCreatingUser(true)}>
              Create New
            </PrimaryButton>
          </div>
        </div>
      )}
      {(!isEmpty(selectedUser) || creatingUser) && (
        <form {...formProps}>
          <Heading level={2}>Personal Info</Heading>
          {!creatingUser && (
            <dl>
              <dt>User ID</dt>
              <dd>
                <CopyToClipboardButton>{selectedUser.UserID}</CopyToClipboardButton>
              </dd>
            </dl>
          )}
          <FormRow>
            <EmailField
              disabled={userFound}
              {...getFieldProps('Username')}
              label="Email"
            />
          </FormRow>
          <FormRow>
            <TextField
              disabled={userFound}
              {...getFieldProps('FirstName')}
              label="First Name"
            />
          </FormRow>
          <FormRow>
            <TextField
              disabled={userFound}
              {...getFieldProps('LastName')}
              label="Last Name"
            />
          </FormRow>
          {!userFound && (
            <>
              <Heading level={2}>Password</Heading>
              {(creatingUser ||
                selectedUser.Username.indexOf('@demoweave.com') !== -1) && (
                <FormRow>
                  <PasswordField
                    {...getFieldProps('Password')}
                    label={creatingUser ? 'Password' : 'Reset Manually'}
                  />
                </FormRow>
              )}
              {!creatingUser && (
                <>
                  <Text>Reset via Email:</Text>
                  <FormRow>
                    <PrimaryButton
                      onClick={() => dispatch(sendPasswordReset(selectedUser))}
                      type="button"
                    >
                      Send Reset Email
                    </PrimaryButton>
                  </FormRow>
                </>
              )}
              <br />
            </>
          )}
          <Heading level={2}>Roles & Permissions</Heading>
          <br />
          <FormRow>
            <MultiselectField {...getFieldProps('Roles')} label="Roles">
              {roleOptions?.map((role, i) => (
                <MultiselectField.Option key={i} value={role}>
                  {role}
                </MultiselectField.Option>
              ))}
            </MultiselectField>
          </FormRow>
          <FormRow>
            <SwitchField
              {...getFieldProps('hasMobileAccess')}
              name="hasMobileAccess"
              label="Access the Weave Mobile App"
              css={styles.RadioMobileAccess}
            />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('TicketID')} label="Ticket ID" />
          </FormRow>
          <Text>Please enter the ticket number associated with this change.</Text>
          <ButtonBar>
            <SecondaryButton type="button" onClick={handleEditClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={!isComplete} type="submit">
              Submit
            </PrimaryButton>
          </ButtonBar>
        </form>
      )}
    </>
  );
};
