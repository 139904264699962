import { isEmpty, isEqual } from 'lodash';

import { DeviceModel } from '../../../../../../../../apis/devices';
import CallRecordModel from '../../../../../../../history-list-container/call-records-container/call-record.model';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { HandoffTypeMetric } from '../handoff.constants';
import { NEW_OFFICE_HOURS_SCHEDULE } from './office-hours-constants';

export const TOTAL_CALLS_PASS_VALUE = 10;
export const INBOUND_CALLS_PASS_VALUE = 1;
export const OUTBOUND_CALLS_PASS_VALUE = 1;
export const PHONES_COUNT_PASS_VALUE = 1;
export const VOICEMAIL_GREET_PASS_VALUE = 'True';
export const OFFICE_HOURS_PASS_VALUE = 'not the default hours';

export const getCallCounts = (callLogs: CallRecordModel[]) => {
  const callCounts = {
    inbound: 0,
    outbound: 0,
  };
  for (const phoneCall of callLogs) {
    const direction = phoneCall.Direction;
    callCounts[direction]++;
  }

  return callCounts;
};

export const getRegisteredPhoneCounts = (devices: DeviceModel[]) =>
  devices.reduce((count: number, device: DeviceModel) => {
    return device.registration?.addr ? ++count : count;
  }, 0);

///////////////////////////////////// METRICS ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
// INBOUND CALLS METRIC
export const inboundCallMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have received at least ${this.expectedValue} inbound call(s) in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.CallLogs],
  expectedValue: INBOUND_CALLS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err) return err.message;

    const callLogs = callLogsData?.data;
    if (!callLogs) return 'no data';

    const callCounts = getCallCounts(callLogs);
    return callCounts.inbound;
  },
  resultFn: function (dependentData) {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err || !callLogsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Small office (minimal call traffic)',
    'New Office (few patients)',
    'Office closed (but phones confirmed to be working)',
  ],
};

// OUTBOUND CALLS METRIC
export const outboundCallsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have made at least ${this.expectedValue} outbound call(s) in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.CallLogs],
  expectedValue: OUTBOUND_CALLS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err) return err.message;

    const callLogs = callLogsData?.data;
    if (!callLogs) return 'no data';

    const callCounts = getCallCounts(callLogs);
    return callCounts.outbound;
  },
  resultFn: function (dependentData) {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err || !callLogsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Small office (minimal call traffic)',
    'New Office (few patients)',
    'Office closed (but phones confirmed to be working)',
  ],
};

// TOTAL CALLS METRIC
export const totalCallsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have total of ${this.expectedValue} calls in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.CallLogs],
  expectedValue: TOTAL_CALLS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err) return err.message;

    const callLogs = callLogsData?.data;
    if (!callLogs) return 'no data';

    const callCounts = getCallCounts(callLogs);
    const total = callCounts.inbound + callCounts.outbound;
    return total;
  },
  resultFn: function (dependentData) {
    const callLogsData = dependentData[DependencyDataKeys.CallLogs];
    const err = callLogsData?.error;
    if (err || !callLogsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Small office (minimal call traffic)',
    'New Office (few patients)',
    'Office closed (but phones confirmed to be working)',
  ],
};

// VOICEMAIL GREETINGS METRIC
export const voicemailGreetingsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have a voicemail greeting recorded and assigned`;
  },
  dependencyDataKeys: [
    DependencyDataKeys.PhoneDepartmentsSchedules,
    DependencyDataKeys.AdminGreetingsVoiceMailbox,
    DependencyDataKeys.MailboxGreetings,
  ],
  expectedValue: VOICEMAIL_GREET_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const mainlineSchedulesGreetingsData =
      dependentData[DependencyDataKeys.PhoneDepartmentsSchedules];
    const adminGreetingsMailboxData =
      dependentData[DependencyDataKeys.AdminGreetingsVoiceMailbox];

    const deptErr = mainlineSchedulesGreetingsData?.error;
    const mailboxErr = adminGreetingsMailboxData?.error;

    if (deptErr && !adminGreetingsMailboxData) {
      return deptErr.message === 'Request failed with status code 404'
        ? `Department was not created`
        : deptErr.message;
    }
    if (mailboxErr && !mainlineSchedulesGreetingsData) {
      return mailboxErr.message === 'Request failed with status code 404'
        ? `Voicemail box was not created`
        : mailboxErr.message;
    }

    const departmentsSchedule = mainlineSchedulesGreetingsData?.data?.data;
    const adminGreetingsMailboxes = adminGreetingsMailboxData?.data;

    if (!departmentsSchedule && !adminGreetingsMailboxes) return 'no data';
    const schedule = departmentsSchedule?.find((schedule) => schedule.Type === 'open');
    const hasDepartmentVMGreeting = schedule?.routingSettings?.Instructions.some(
      (instruction) => instruction.VoicemailPrompt?.VoicemailBoxID
    );

    const hasPhoneTreeGreeting = schedule?.routingSettings?.Instructions.some(
      (instruction) => instruction.IVRMenu?.PhoneTreeID
    );

    const hasVoiceMailboxVMGreeting = adminGreetingsMailboxes?.some(
      (mailboxData) => mailboxData.MailboxID
    );

    const hasDepartmentPhoneTreeGreeting =
      hasDepartmentVMGreeting || hasPhoneTreeGreeting || hasVoiceMailboxVMGreeting;

    return hasDepartmentPhoneTreeGreeting ? VOICEMAIL_GREET_PASS_VALUE : 'False';
  },
  resultFn: function (dependentData) {
    const mainlineSchedulesGreetingsData =
      dependentData[DependencyDataKeys.PhoneDepartmentsSchedules];
    const adminGreetingsMailboxData =
      dependentData[DependencyDataKeys.AdminGreetingsVoiceMailbox];

    const deptErr = mainlineSchedulesGreetingsData?.error;
    const mailboxErr = adminGreetingsMailboxData?.error;
    if (deptErr && !adminGreetingsMailboxData) {
      return Result.Error;
    }
    if (mailboxErr && !mainlineSchedulesGreetingsData) {
      return Result.Error;
    }
    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;
    return Result.Fail;
  },
  exceptions: [
    'No urgency to record VM (but office confirms that they will)',
    'Office prefers the default recording',
    'Office is not using VM',
  ],
};

// OFFICE HOURS METRIC
export const officeHoursMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Office Hours must be: ${this.expectedValue}`;
  },
  dependencyDataKeys: [DependencyDataKeys.PhoneDepartmentsSchedules],
  expectedValue: OFFICE_HOURS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const officeHoursData = dependentData[DependencyDataKeys.PhoneDepartmentsSchedules];
    const err = officeHoursData?.error;
    if (err) return err.message;

    const officeHours = officeHoursData?.data;
    const officeHoursScheduleRules = officeHours?.data.map((newId) => newId.rules);

    if (!officeHours) return 'no data';

    if (
      !isEmpty(officeHours) &&
      !isEqual(officeHoursScheduleRules, NEW_OFFICE_HOURS_SCHEDULE)
    ) {
      return OFFICE_HOURS_PASS_VALUE;
    }
    return 'is default hours';
  },
  resultFn: function (dependentData) {
    const officeHoursData = dependentData[DependencyDataKeys.PhoneDepartmentsSchedules];
    const err = officeHoursData?.error;
    if (err || !officeHoursData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Office prefers default hours',
    'New offices do not have established hours',
  ],
};

// PHONES REGISTERED METRIC
export const phonesRegisteredMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have ${this.expectedValue} phone(s) registered`;
  },
  dependencyDataKeys: [DependencyDataKeys.PhoneDevices],
  expectedValue: PHONES_COUNT_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const devicesData = dependentData[DependencyDataKeys.PhoneDevices];
    const err = devicesData?.error;
    if (err) return err.message;

    const devices = devicesData?.data;
    if (!devices) return 'no data';

    const registeredDevices = getRegisteredPhoneCounts(devices);
    return registeredDevices;
  },
  resultFn: function (dependentData) {
    const devices = dependentData[DependencyDataKeys.PhoneDevices];
    const err = devices?.error;
    if (err || !devices?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: ['Software only accounts'],
};
