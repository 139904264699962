import { css } from '@emotion/core';
import {
  Heading,
  Modal,
  ModalControlResponse,
  NakedButton,
  Text,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { LocationFeatureV2 } from 'models/location-feature.model';
import { FC, useEffect, useState } from 'react';
import {
  getFeatureWiseQuotaList,
  getQuotaAmountText,
  getQuotaTextByFeature,
} from '../customization-page.utils';

export interface EditCustomizationQuotaModalProps {
  feature: LocationFeatureV2 | null;
  modalControls: ModalControlResponse;
  onQuotaUpdate: (quota: string) => void;
}

export const EditCustomizationQuotaModal: FC<EditCustomizationQuotaModalProps> = ({
  feature,
  modalControls,
  onQuotaUpdate,
}) => {
  const [selectedQuota, setSelectedQuota] = useState<string | undefined>(feature?.quota);

  const handleSubmitClick = () => {
    !!selectedQuota && onQuotaUpdate(selectedQuota);
    modalControls.closeModal();
  };

  useEffect(() => {
    if (modalControls.modalProps.show) {
      setSelectedQuota(feature?.quota);
    }
  }, [feature?.quota, modalControls.modalProps.show]);

  return (
    <Modal {...modalControls.modalProps} maxWidth={520}>
      <Modal.Header onClose={modalControls.closeModal}>
        {feature?.name} Allowance
      </Modal.Header>
      <Modal.Body
        css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}
      >
        <Text size="large">
          Specify the amount of monthly{' '}
          {getQuotaTextByFeature(feature?.name)?.toLocaleLowerCase()} enabled for this
          location.
        </Text>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Heading level={3} css={{ marginBottom: theme.spacing(0.25) }}>
            Allowance
          </Heading>
          <Text color="light" size="medium" css={{ margin: 0 }}>
            Choose one
          </Text>
        </div>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            paddingBottom: theme.spacing(3),
          }}
        >
          {getFeatureWiseQuotaList(feature?.name).map((quota) => (
            <QuotaOption
              key={quota}
              quota={quota}
              selectedQuota={selectedQuota}
              featureName={feature?.name}
              onSelect={setSelectedQuota}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Actions
        disablePrimary={!selectedQuota}
        primaryLabel="Apply Changes"
        onPrimaryClick={handleSubmitClick}
        secondaryLabel="Cancel"
        onSecondaryClick={modalControls.closeModal}
      />
    </Modal>
  );
};

interface QuotaOptionProps {
  quota: string;
  selectedQuota?: string;
  featureName?: string;
  onSelect: (quota: string) => void;
}

const QuotaOption: FC<QuotaOptionProps> = ({
  quota,
  selectedQuota,
  featureName,
  onSelect,
}) => {
  const isSelected = selectedQuota === quota;
  return (
    <NakedButton onClick={() => onSelect(quota)} css={buttonStyles}>
      <Text
        color={isSelected ? 'white' : 'default'}
        weight={isSelected ? 'bold' : 'regular'}
        css={[quotaOptionStyles, isSelected && selectedOptionStyles]}
      >
        {getQuotaAmountText(quota, featureName)}
      </Text>
    </NakedButton>
  );
};

const buttonStyles = css`
  :focus {
    outline: none;
  }
`;

const quotaOptionStyles = css`
  border: 1px solid ${theme.colors.gray400};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(1.5, 0)};
  min-width: 150px;
  margin: 0;
  :hover {
    background-color: ${theme.colors.gray200};
  }
`;

const selectedOptionStyles = css`
  background-color: ${theme.colors.weaveBlue};
  :hover {
    background-color: ${theme.colors.hoverBlue};
  }
`;
