export interface LocationFeature {
  id: string;
  name: string;
  description: string;
  state: FeatureStates;
}

export enum FeatureStates {
  Hide = 'hide',
  Promote = 'promote',
  Active = 'active',
  Trial = 'trial',
}

export enum CustomizationFlagStatesV2 {
  ACTIVE = 'ACTIVE',
  PROMOTE = 'PROMOTE',
  HIDE = 'HIDE',
  TRIAL = 'TRIAL',
}

export interface LocationFeatureV2 {
  id: string;
  name: string;
  description: string;
  featureEnum: string;
  state: CustomizationFlagStatesV2;
  isFreeTrial: boolean;
  quota: string;
}
