import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import {
  TrashIcon,
  IconButton,
  Text,
  PrimaryButton,
  ButtonBar,
  SecondaryButton,
  EditIcon,
  Modal,
  DropdownField,
  useFormField,
  useModalControl,
  FormRow,
  CheckboxField,
  useForm,
  DatePickerField,
  ValidatorFieldState,
  PhoneField,
  PlusIcon,
  ContentLoader,
} from '@weave/design-system';
import { Loader } from '../../shared/loader/loader.component';
import { chunk, cloneDeep, noop, set } from 'lodash';
import { SuccessSVG } from '../porting-hub/success-svg';
import { portingCardStyles } from './porting-card.styles';
import {
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
  isEqualStringUnorderedArray,
  convertToDateWithSpecificTime,
  convertUsDateToIsoDateFormat,
  getStatusForPorting,
  getPortOrderType,
  getErrorMessageFromResponseError,
} from '../utils/porting-utils';
import { theme } from '@weave/theme-original';
import {
  sendToSalesforce,
  clonePortingData,
  cancelPortOrder,
  getPortingValidationData,
  getAvailableFOCDates,
  portingSchemaApi,
} from '../../../apis/porting/porting.api';
import {
  PortingData,
  PortingRequest,
  MediaData,
  PortingDataUpdateRequest,
  PortInCreateByPortingDataIDResponse,
  PortingDataUpdateResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import {
  PortingType,
  PortType,
  PortStatus,
  NumberType,
  Provider,
} from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { getErrorMessage } from 'redux/axios';
import { MediaType, ValidationData } from 'apis/porting/porting.types';
import { format, parseISO } from 'date-fns';
import { PortingCardOfficeInfo } from './porting-card-office-info.component';
import { PortingCardAttachments } from './port-card-attachments.component';
import { formatInTimeZone } from 'date-fns-tz';
import { CountryCodes } from '../utils/port-constants';
import { useAlert } from '@weave/alert-system';
import { MessageDialogue } from '../utils/message-dialogue.component';
import { iconButtonSvgPrimary } from 'components/location-sidebar-container/location-sidebar.styles';
import _ from 'lodash';
import { portingValidationError } from './error-code';
import { SupRequestModal } from './sup-request-modal';
import { Page } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux/store/store.model';
import { getUser } from 'redux/actions/auth/user/user.action';
import {
  DataCenters,
  LocationModel,
  selectCurrentLocationDataCenter,
} from '../../../redux/actions/location';
import { useDownloadLoa } from '../hooks/use-download-loa';
import { useDebugMode } from '../../debug/use-debug-mode';
import { DeletePortingDataButton } from './delete-porting-data-button';
import { useDeletePortingData } from '../hooks/use-delete-porting-data';
import { useHistory } from 'react-router-dom';
import { ONBOARDING, injectParams } from '../../../constants/paths';

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
  actionLabel: string;
  onClose: () => void;
  onActionLabelClick: () => void;
};

interface PortingCardProps {
  port: PortingData;
  isLoadingUpdatePortingData: boolean;
  currentLocation: LocationModel;
  back: () => void;
  getStatusColor: (port) => string;
  setPortingDetails: (port) => void;
  updatePortingData: (
    req: PortingDataUpdateRequest
  ) => Promise<PortingDataUpdateResponse>;
}

const cancellationTimeDiffInMin = 72 * 60 + 5; // represents minutes in 72h + 5min for safe play

export const PortingCard = ({
  port,
  currentLocation,
  isLoadingUpdatePortingData,
  back,
  getStatusColor,
  setPortingDetails,
  updatePortingData,
}: PortingCardProps) => {
  const alert = useAlert();
  const [portOrderUrl, setPortOrderUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [duplicateError, setDuplicateError] = useState<string>('');
  const [portingInfoEdit, setPortingInfoEdit] = useState<boolean>(false);
  const [phoneNumbersEdit, setPhoneNumbersEdit] = useState<boolean>(false);
  const [officeInfoEdit, setOfficeInfoEdit] = useState<boolean>(false);
  const [canEditAttachments, setCanEditAttachments] = useState<boolean>(false);
  const [portingInfoError, setPortingInfoError] = useState<string>('');
  const [showCancelPortModal, setShowCancelPortModal] = useState<boolean>(false);
  const [showCancelLoading, setShowCancelLoading] = useState<boolean>(false);
  const [portingProviderError, setPortingProviderError] = useState<string>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);
  const [portingValidationData, setPortingValidationData] = useState<ValidationData[]>(
    []
  );
  const [documentError, setDocumentError] = useState<ValidationData[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState<ValidationData[]>([]);
  const [portingInformationError, setPortingInformationError] = useState<
    ValidationData[]
  >([]);
  const [officeInformationError, setOfficeInformationError] = useState<ValidationData[]>(
    []
  );
  const [missingRequestedPort, setMissingReqestedPort] = useState(false);
  const [missingPortingCompany, setMissingPortingCompany] = useState(false);
  const {
    modalProps: messageModalProps,
    closeModal,
    triggerProps: triggerModal,
  } = useModalControl();

  const {
    modalProps: cancelConfirmationModalProps,
    closeModal: closeCancelModal,
    triggerProps: triggerCancelProps,
  } = useModalControl();

  const [messagePortModal, setMessagePortModal] = useState<MessageModal>({
    header: '',
    body: '',
    success: true,
    actionLabel: 'Okay',
    onClose: () => {},
    onActionLabelClick: () => {},
  });
  const [focDates, setFocDates] = useState<string[]>([]);
  const [minDateFoc, setMinDateFoc] = useState<string>('');
  const [maxDateFoc, setMaxDateFoc] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [duplicateNumberError, setDuplicateNumberError] = useState<boolean>(false);
  const [removedDateInformation, setRemovedDateInformation] = useState<string>('');
  const dispatch = useDispatch();
  const username = useSelector((state: Store) => state.auth.user.username);
  const currentLocationDataCenter: ReturnType<typeof selectCurrentLocationDataCenter> =
    useSelector(selectCurrentLocationDataCenter);

  const { isDebugModeEnabled } = useDebugMode();

  const { deletePortingData } = useDeletePortingData();

  useEffect(() => {
    dispatch(getUser({ username }));
  }, [username]);

  const { downloadLoa, isLoading: downloadLOALoading } = useDownloadLoa();

  const existingNumberValues: PortingRequest[] = [];
  const existingFaxValue: PortingRequest[] = [];

  port?.portingRequests?.forEach(
    (portingRequest) =>
      portingRequest.numberType === NumberType.NUMBER_TYPE_PHONE &&
      existingNumberValues.push(portingRequest)
  );

  port?.portingRequests?.forEach(
    (portingRequest) =>
      portingRequest.numberType === NumberType.NUMBER_TYPE_FAX &&
      existingFaxValue.push(portingRequest)
  );

  const [newPhoneNumbersList, setNewPhoneNumbersList] =
    useState<PortingRequest[]>(existingNumberValues);
  const [newFaxNumbersList, setNewFaxNumbersList] =
    useState<PortingRequest[]>(existingFaxValue);

  const providerDropdownProps = useFormField({
    type: 'text',
    value: port.provider ? port.provider : Provider.PROVIDER_UNSPECIFIED,
  });

  const getPhoneNumbers = (portingRequests: PortingRequest[]): string[] =>
    portingRequests?.map((portingRequest) => portingRequest?.phoneNumber ?? '');

  const setDisableProperty = () => {
    if (duplicateNumberError) {
      return true;
    }
    const phoneNumbersLengthsArray = newPhoneNumbersList?.map(
      (data) => data?.phoneNumber?.length
    );
    const faxNumbersLengthsArray = newFaxNumbersList?.map(
      (data) => data?.phoneNumber?.length
    );

    if (!faxNumbersLengthsArray.length && !phoneNumbersLengthsArray.length) {
      return true;
    }

    if (
      !isEqualStringUnorderedArray(
        getPhoneNumbers(existingNumberValues),
        getPhoneNumbers(newPhoneNumbersList)
      ) ||
      !isEqualStringUnorderedArray(
        getPhoneNumbers(existingFaxValue),
        getPhoneNumbers(newFaxNumbersList)
      )
    ) {
      if (
        phoneNumbersLengthsArray?.some((el) => (el ?? 0) < 10) ||
        faxNumbersLengthsArray?.some((el) => (el ?? 0) < 10)
      )
        return true;
      else {
        return false;
      }
    }
    return true;
  };

  const getTypeOfPortingRequest = (): string => {
    switch (port?.portType) {
      case PortingType.PORTING_TYPE_MAIN:
        return 'Main';
      case PortingType.PORTING_TYPE_INTERNAL:
        return 'Internal';
      default:
        return 'Unknown';
    }
  };

  useEffect(() => {
    const getPortingOrderUrl = async () => {
      if (!port.id || !port.provider || !portingRequest?.portOrderNumber) return;

      try {
        const result = await portingSchemaApi.getPortOrderUrl(port.id);
        setPortOrderUrl(result.portOrderUrl ?? '');
      } catch (error) {
        alert.error(`Error fetching the port order URL ${error}`);
      }
    };

    getPortingOrderUrl();
  }, []);

  const findDuplicateValue = (arr1: string[], arr2: string[]): string[] => {
    return arr1?.filter((arr1Item) => arr2.includes(arr1Item));
  };

  useEffect(() => {
    const newPhnArray = newPhoneNumbersList?.map((phnData) => {
      return phnData?.phoneNumber ?? '';
    });
    const newFaxArray = newFaxNumbersList?.map((phnData) => {
      return phnData?.phoneNumber ?? '';
    });
    const result = findDuplicateValue(newPhnArray, newFaxArray);
    setDuplicateNumberError(!!result?.length);
  }, [newPhoneNumbersList]);

  useEffect(() => {
    const newPhnArray = newPhoneNumbersList?.map((phnData) => {
      return phnData?.phoneNumber ?? '';
    });
    const newFaxArray = newFaxNumbersList?.map((phnData) => {
      return phnData?.phoneNumber ?? '';
    });
    const result = findDuplicateValue(newPhnArray, newFaxArray);
    setDuplicateNumberError(!!result?.length);
  }, [newFaxNumbersList]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [missingRequestedPort, missingPortingCompany]);

  const handleNewNumberAddition = (numberType: NumberType) => {
    setDuplicateError('');
    numberType === NumberType.NUMBER_TYPE_PHONE
      ? setNewPhoneNumbersList([
          ...newPhoneNumbersList,
          {
            phoneNumber: '',
            portType: PortType.PORT_TYPE_UNSPECIFIED,
            numberType: NumberType.NUMBER_TYPE_PHONE,
            portingDataId: port.id,
            requestedFirmOrderCommitmentDate:
              port.portingRequests?.[0].requestedFirmOrderCommitmentDate,
          },
        ])
      : setNewFaxNumbersList([
          ...newFaxNumbersList,
          {
            phoneNumber: '',
            portType: PortType.PORT_TYPE_UNSPECIFIED,
            numberType: NumberType.NUMBER_TYPE_FAX,
            portingDataId: port.id,
            requestedFirmOrderCommitmentDate:
              port.portingRequests?.[0].requestedFirmOrderCommitmentDate,
          },
        ]);
  };

  const handleNumberDeletion = (findIndex, numberType) => {
    setDuplicateError('');
    numberType === NumberType.NUMBER_TYPE_PHONE
      ? setNewPhoneNumbersList(
          newPhoneNumbersList.filter((numbers, index) => index !== findIndex)
        )
      : setNewFaxNumbersList(
          newFaxNumbersList.filter((numbers, index) => index !== findIndex)
        );
  };

  const handleNumberChange = (value, index, numberType) => {
    setDuplicateError('');
    const numberValue = value.value;
    const list =
      numberType === NumberType.NUMBER_TYPE_PHONE
        ? [...newPhoneNumbersList]
        : [...newFaxNumbersList];
    list[index].phoneNumber = numberValue;
    numberType === NumberType.NUMBER_TYPE_PHONE
      ? setNewPhoneNumbersList(list)
      : setNewFaxNumbersList(list);
  };

  const today = new Date();
  const minDate = format(new Date(), 'MM/dd/yyyy');
  const getFormattedDate = (date?: string): string => {
    return format(parseISO(date?.split('T')[0] ?? ''), 'MM/dd/yyyy');
  };

  const getAvailableFOCDate = async () => {
    if (!port.id) return;

    // PT-TODO: migrate to schema
    const availableDatesForFoc = await getAvailableFOCDates(port.id);
    setRemovedDateInformation(availableDatesForFoc?.[0].split('T')[1]);
    const availableDates = availableDatesForFoc.map((item) => item.split('T')[0]);
    setFocDates(availableDates);
    setMinDateFoc(format(new Date(availableDates?.[0]), 'MM/dd/yyyy'));
    setMaxDateFoc(
      format(new Date(availableDates?.[availableDates?.length - 1]), 'MM/dd/yyyy')
    );
  };

  const getRequestedPortDate = (): string =>
    getFormattedDate(port?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate);

  const { getFieldProps } = useForm({
    fields: {
      asapPort: { type: 'checkbox', value: port.requestAsapPort },
      phone: { type: 'phone' },
      requestedPortDate: {
        type: 'datePicker',
        minDate: getUTCDateWithPortingTimeString(today.toISOString()),
        value: port?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate
          ? getRequestedPortDate()
          : '',
        validator: ({ value }: ValidatorFieldState<'datePicker'>) => {
          const formattedValue = convertUsDateToIsoDateFormat(value);
          const formattedMinDate = convertUsDateToIsoDateFormat(minDate);

          return formattedValue < formattedMinDate
            ? 'Requested port date cannot be set in the past'
            : '';
        },
      },
    },
  });

  const requestPortDate = getFieldProps('requestedPortDate').value;

  useEffect(() => {
    setPortingProviderError('');
  }, [requestPortDate]);

  useEffect(() => {
    setDisableProperty();
  }, [newPhoneNumbersList, newFaxNumbersList]);

  const filterPortingValidations = (
    portingValidationData: ValidationData[],
    minErrorCode: number,
    maxErrorCode: number
  ): ValidationData[] => {
    return portingValidationData?.filter((portingInformationData) => {
      return (
        portingInformationData.error_code >= minErrorCode &&
        portingInformationData.error_code <= maxErrorCode
      );
    });
  };

  const getValidationDataForPorting = async () => {
    if (!port.id) return;

    try {
      // PT-TODO: migrate to schema
      const validationData = await getPortingValidationData(port?.id);
      const portingValidationData = validationData?.data?.data?.porting_validations;
      const porting_Information_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.porting_information_error.min_error_code,
        portingValidationError.porting_information_error.max_error_code
      );

      const office_Information_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.office_information_error.min_error_code,
        portingValidationError.office_information_error.max_error_code
      );
      const document_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.document_error.min_error_code,
        portingValidationError.document_error.max_error_code
      );

      const phone_Number_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.phone_information_error.min_error_code,
        portingValidationError.phone_information_error.max_error_code
      );

      setPortingValidationData(portingValidationData);
      setPortingInformationError(porting_Information_Error);
      setDocumentError(document_Error);
      setPhoneNumberError(phone_Number_Error);
      setOfficeInformationError(office_Information_Error);
    } catch (error) {
      setError(`Unable to fetch the validation data,please try again${error}`);
    }
  };

  useEffect(() => {
    if (
      port?.portingRequests?.[0].portingStatus ===
      PortStatus.PORT_STATUS_VALIDATION_FAILED
    ) {
      getValidationDataForPorting();
    }
  }, [port]);

  useEffect(() => {
    const portCopy = cloneDeep(port);
    const date = requestPortDate && convertToDateWithSpecificTime(requestPortDate);
    const dateInUTCZone =
      requestPortDate &&
      formatInTimeZone(date, 'America/New_York', 'yyyy-MM-dd') + 'T00:00:00Z';
    for (const portingRequest of portCopy?.portingRequests ?? []) {
      portingRequest.requestedFirmOrderCommitmentDate = dateInUTCZone;
    }
    setPortingDetails(portCopy);
  }, [requestPortDate]);

  useEffect(() => {
    if (canEditAttachments) {
      setError(validateMediaData(port.customerPhoneBillMedia));
    }
  }, [port.customerPhoneBillMedia]);

  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      clearSubmitPortingDetails();
    };
  }, [port.id]);

  const updatePortProvider = async () => {
    setPortingInfoError('');
    setPortingProviderError('');
    setPortingInfoEdit(true);

    if (!port.provider) {
      port.provider = Provider.PROVIDER_UNSPECIFIED;
    }
    const portCopy = cloneDeep(port);
    portCopy.provider = providerDropdownProps.value as Provider;
    if (portCopy?.portingRequests?.[0].portingCarrier) {
      portCopy.portingRequests[0].portingCarrier = portCopy.provider;
    }
    setPortingDetails(portCopy);
    await updatePortingData({ portingData: portCopy, portingDataId: portCopy.id });

    setPortingInfoEdit(false);
  };

  type SplitPortingRequestResult = {
    status: PortInCreateByPortingDataIDResponse;
    port?: PortingData;
  };
  const [splitPortingRequestsResults, setSplitPortingRequestsResults] = useState<
    SplitPortingRequestResult[]
  >([]);
  const [splitPortingRequests, setSplitPortingRequests] = useState<PortingData[]>([]);
  const [submitPortingDetails, setSubmitPortingDetails] =
    useState<PortInCreateByPortingDataIDResponse | null>(null);
  const clearSubmitPortingDetails = () => setSubmitPortingDetails(null);
  const [submitPortingLoading, setSubmitPortingLoading] = useState<boolean>(false);

  const SIP_PEER_IDS = {
    [DataCenters.CentralIA]: '549788',
    [DataCenters.EasternVA]: '525692',
    [DataCenters.PacificCA]: '539695',
    [DataCenters.MountainUT]: '525694',
    [DataCenters.US1Central1]: '549788',
  };

  const handleErrorCodeData = async (portingData: PortingData) => {
    const response = await portingSchemaApi.portInStatusByPortingDataId({
      portingDataId: portingData.id,
    });
    if (
      response.progressStatus === 'VALIDATION_FAILED' &&
      portingData?.portingRequests?.[0]
    ) {
      portingData.portingRequests[0].portingStatus =
        PortStatus.PORT_STATUS_VALIDATION_FAILED;
      setPortingDetails(portingData);
    }
  };

  const submitSplitPortingRequests = async (portingData: PortingData) => {
    const originalPortOrder = cloneDeep(portingData);
    setSubmitPortingLoading(true);

    try {
      try {
        // set the original to status PORT_STATUS_SPLIT_PORT
        for (const portingRequest of originalPortOrder.portingRequests ?? []) {
          portingRequest.portingStatus = PortStatus.PORT_STATUS_SPLIT_PORT;
          portingRequest.portOrderNumber = 'No Port Order Number - Split Port';
        }

        await portingSchemaApi.updatePortingDataById({
          portingData: originalPortOrder,
          portingDataId: originalPortOrder.id,
        });
      } catch {
        alert.error('Failed to update original porting request to split status.');
      }

      const results: SplitPortingRequestResult[] = [];

      for (const portingRequest of splitPortingRequests) {
        delete portingRequest.id;
        delete portingRequest.createdAt;
        delete portingRequest.updatedAt;

        if (!portingRequest.serviceHouseNumber) {
          const streetSplit = portingRequest?.serviceStreet1?.split(' ') ?? [];
          portingRequest.serviceHouseNumber = streetSplit?.[0];
          streetSplit?.shift();
          portingRequest.serviceStreet1 = streetSplit?.join(' ');
        }

        const dataCenter = currentLocationDataCenter;

        if (dataCenter) {
          portingRequest.sipPeerId = SIP_PEER_IDS[dataCenter];
        }

        try {
          // create new portingData objects
          const res = await portingSchemaApi.createPortingData({
            portingData: portingRequest,
          });
          const newPortOrderId = res.portingData?.[0].id;

          // submit new portingData objects
          const response = await portingSchemaApi.portInCreateByPortingDataId({
            portingDataId: newPortOrderId,
          });

          const status = response; // submitPortingDetails

          // get the new portingData object after submission
          const newPortingDataResponse =
            await portingSchemaApi.getPortingDataByLocationIds(
              currentLocation.LocationID
            );

          let updatedPort: PortingData | undefined = undefined;
          const newPortingData = newPortingDataResponse.portingData ?? [];
          for (const portingData of newPortingData) {
            if (portingData.id === newPortOrderId) {
              updatedPort = portingData;
            }
          }

          results.push({ status, port: updatedPort });
        } catch (error) {
          if (error?.response?.status === 400) {
            handleErrorCodeData(originalPortOrder);
          }
          const errorMessage = getErrorMessageFromResponseError(error);
          results.push({
            status: {
              hasError: true,
              errors: [
                {
                  description:
                    errorMessage || 'Network request failed. Simply try resubmitting.',
                },
              ],
            },
          });
        }
      }

      setSplitPortingRequestsResults(results);
      setSplitPortingRequests([]);
    } catch {
      alert.error('Could not split and submit porting requests.');
      setSplitPortingRequests([]);
      setSplitPortingRequestsResults([
        {
          status: {
            hasError: true,
            errors: [{ description: 'Network request failed. Simply try resubmitting.' }],
          },
          port: undefined,
        },
      ]);
    } finally {
      setSubmitPortingLoading(false);
    }
  };

  const submitPorting = async (portingData: PortingData) => {
    setSubmitPortingLoading(true);
    const portClone = cloneDeep(portingData);
    try {
      if (!portClone.serviceHouseNumber) {
        const streetSplit = portClone?.serviceStreet1?.split(' ') ?? [];
        portClone.serviceHouseNumber = streetSplit[0];
        streetSplit.shift();
        portClone.serviceStreet1 = streetSplit.join(' ');
      }

      const dataCenter = currentLocationDataCenter;

      if (dataCenter) {
        portClone.sipPeerId = SIP_PEER_IDS[dataCenter];
      }

      const res = await portingSchemaApi.updatePortingDataById({
        portingData: portClone,
        portingDataId: portClone.id,
      });
      const updatedPort = res.portingData;

      const response = await portingSchemaApi.portInCreateByPortingDataId({
        portingDataId: updatedPort?.id,
      });

      setSubmitPortingDetails(response);
    } catch (error) {
      if (error?.response?.status === 400) {
        handleErrorCodeData(portClone);
      }
      const errorMessage = getErrorMessageFromResponseError(error);
      setSubmitPortingDetails({
        hasError: true,
        errors: [
          {
            description:
              errorMessage || 'Network request failed. Simply try resubmitting.',
          },
        ],
      });
    } finally {
      setSubmitPortingLoading(false);
    }
  };

  const createPortingDataObject = (lnpNumbers: string[], port: PortingData) => {
    const portingRequests: PortingRequest[] = [];

    for (const num of lnpNumbers) {
      for (const portRequest of port.portingRequests ?? []) {
        const cleanPortRequestNumber = (portRequest?.phoneNumber ?? '')
          .replace(/\D/g, '')
          .replace(/^1/, '');

        if (cleanPortRequestNumber === num) {
          portingRequests.push({
            phoneNumber: portRequest.phoneNumber,
            portType: portRequest.portType,
            numberType: portRequest.numberType,
            requestedFirmOrderCommitmentDate:
              portRequest.requestedFirmOrderCommitmentDate,
          });
        }
      }
    }

    const billingPhoneNumber = portingRequests[0]?.phoneNumber ?? port.billingPhoneNumber;
    return Object.assign({}, port, { portingRequests, billingPhoneNumber });
  };

  const checkPortability = async (portOrder: PortingData) => {
    setSubmitPortingLoading(true);
    try {
      const portClone = cloneDeep(portOrder);
      const LNPNumbers: string[] = [];
      for (const req of portClone?.portingRequests ?? []) {
        if (req.phoneNumber) {
          LNPNumbers.push(req.phoneNumber);
        }
      }
      const provider = portClone.provider;
      const LNPCheckResponse = await portingSchemaApi.checkPortability({
        phoneNumbers: LNPNumbers,
        provider,
      });
      const portingDataObjects: PortingData[] = [];

      if (LNPCheckResponse.errors) {
        for (const error of LNPCheckResponse.errors) {
          alert.error(
            `Check portability failed for ${error.phoneNumbers}. Not Portable Reason ${error.description}`
          );
          setSplitPortingRequests([]);
        }
      }

      if (LNPCheckResponse.portabilityErrors) {
        for (const portabilityError of LNPCheckResponse.portabilityErrors) {
          alert.error(
            `Check portability failed for ${portabilityError.phoneNumbers}. Not Portable Reason ${portabilityError.description}`
          );
        }
        setSplitPortingRequests([]);
      }

      if (
        !LNPCheckResponse.portableNumbers ||
        LNPCheckResponse.portableNumbers?.length === 0
      ) {
        const numbersDetail =
          'Number(s): ' +
          (LNPCheckResponse.unsupportedRateCenters ?? [])
            .flatMap((a_rate_center) => a_rate_center.phoneNumbers)
            .join(', ');

        alert.error(
          `Numbers not eligible for porting. Port type: ${LNPCheckResponse.portType} , ${numbersDetail}`
        );
        setSplitPortingRequests([]);
      } else {
        if ((LNPCheckResponse?.supportedTollFreeNumbers?.length ?? 0) > 0) {
          portingDataObjects.push(
            createPortingDataObject(
              LNPCheckResponse.supportedTollFreeNumbers ?? [],
              portClone
            )
          );
        }
        if ((LNPCheckResponse.unsupportedTollFreeNumbers?.length ?? 0) > 0) {
          portingDataObjects.push(
            createPortingDataObject(
              LNPCheckResponse.unsupportedTollFreeNumbers ?? [],
              portClone
            )
          );
        }
        if ((LNPCheckResponse.supportedLosingCarriers?.length ?? 0) > 0) {
          for (const carrier of LNPCheckResponse.supportedLosingCarriers ?? []) {
            portingDataObjects.push(
              createPortingDataObject(carrier.phoneNumbers ?? [], portClone)
            );
          }
        }
        if ((LNPCheckResponse.unsupportedLosingCarriers?.length ?? 0) > 0) {
          let allNumbers: string[] = [];
          for (const carrier of LNPCheckResponse.unsupportedLosingCarriers ?? []) {
            allNumbers = allNumbers.concat(carrier.phoneNumbers ?? []);
          }
          portingDataObjects.push(createPortingDataObject(allNumbers, portClone));
        }

        if (portingDataObjects.length === 1) {
          submitPorting(portOrder);
        } else {
          // This would just save the portingData objects in the redux store and also the the amount of objects (length of the array)
          // it would then just be passed back down to be used in this component through drill propping.
          // It might also be used in the submitting of port orders
          // This is what was saved in the redux store:
          //   submitPortingLoading: false,
          // splitPortingRequest: action.payload.length,
          // splitPortingRequests: action.payload,
          // yield put(checkPortabilitySuccess(portingDataObjects));
          // setSplitPortingRequest(portingDataObjects.length);
          setSplitPortingRequests(portingDataObjects);
        }
      }
    } catch {
      alert.error('Could not submit this port order. Failed to check portability.');
      setSplitPortingRequests([]);
    } finally {
      setSubmitPortingLoading(false);
    }
  };

  const resetPortabilityCheck = () => {
    setSplitPortingRequests([]);
    setSplitPortingRequestsResults([]);
  };

  const submit = () => {
    const err = validate();
    if (!err) {
      setError('');
      checkPortability(port);
    } else {
      setError(err);
    }
  };

  const validate = (): string => {
    if (!port?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate) {
      setMissingReqestedPort(true);
    }
    if (!port?.provider) {
      setMissingPortingCompany(true);
    }
    if (
      !(
        port?.portingRequests?.[0]?.requestedFirmOrderCommitmentDate &&
        port?.companyName &&
        port?.authorizedUserFirstName &&
        port?.phoneServiceAccountNumber &&
        port?.serviceCity &&
        port?.serviceStreet1 &&
        port?.serviceZip &&
        port?.serviceState &&
        port?.provider
      )
    ) {
      return 'Oops! We were unable to submit your port. Please make sure fields highlighted field are not empty and resubmit.';
    }

    return validateMediaData(port.customerPhoneBillMedia);
  };

  const validateMediaData = (mediaData?: MediaData[]): string => {
    if (port?.serviceCountry === CountryCodes.USA.Code) {
      return '';
    }
    if (
      (!mediaData ||
        mediaData.length === 0 ||
        mediaData?.findIndex((md: MediaData) => md.mediaType === MediaType.phoneBill) ===
          -1) &&
      !port.reasonBillNotAvailable
    ) {
      return 'Either phone bill should be uploaded or reason of bill not available should be provided.';
    }

    return '';
  };

  const handleSendToSalesforce = async () => {
    if (!port.id) return;

    setShowLoading(true);
    try {
      // PT-TODO: migrate to schema
      await sendToSalesforce(port.id);
      setShowLoading(false);
      setMessagePortModal({
        body: 'Successfully sent to salesforce',
        header: 'Success',
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } catch (error) {
      console.error(error);
      setShowLoading(false);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      triggerModal.onClick();
    }
  };

  const handleDrawerToggle = (
    drawer: string,
    toggleState: boolean,
    disableToggle = false
  ) => {
    switch (drawer) {
      case 'porting':
        setPortingInfoEdit(!toggleState);
        break;
      case 'phone':
        setPhoneNumbersEdit(!toggleState);
        break;
      case 'office':
        setOfficeInfoEdit(!toggleState);
        break;
      case 'attachments':
        if (!disableToggle) setCanEditAttachments(!toggleState);
        else {
          alert.warning('Please complete the form and save.');
        }
        break;
      default:
        break;
    }
  };

  const portingRequest = port.portingRequests?.[0];
  const filterDidsNumber = port?.portingRequests?.filter(
    (portingNumber) => portingNumber.numberType === NumberType.NUMBER_TYPE_PHONE
  );
  const filterFaxDidsNumber = port?.portingRequests?.filter(
    (portingNumber) => portingNumber.numberType === NumberType.NUMBER_TYPE_FAX
  );
  const chunkedPhoneNumbers: PortingRequest[][] = chunk(filterDidsNumber, 9);
  const chunkedFaxNumbers: PortingRequest[][] = chunk(filterFaxDidsNumber, 9);
  const invalidCancellationPortStatuses = [
    PortStatus.PORT_STATUS_CANCELLED,
    PortStatus.PORT_STATUS_CANCEL_PENDING,
    PortStatus.PORT_STATUS_CANCEL_SUBMITTED,
    PortStatus.PORT_STATUS_REQUESTED_CANCEL,
    PortStatus.PORT_STATUS_COMPLETED,
    PortStatus.PORT_STATUS_SPLIT_PORT,
    PortStatus.PORT_STATUS_ACTIVATION_IN_PROGRESS,
    PortStatus.PORT_STATUS_VALIDATE_TFNS,
  ];

  const invalidSendToSaleForceStatus = [
    PortStatus.PORT_STATUS_COMPLETED,
    PortStatus.PORT_STATUS_SPLIT_PORT,
  ];

  const validSUPRequestPortStatuses = [PortStatus.PORT_STATUS_FOC];

  const showPortOrderCancelButton = (): boolean => {
    if (portingRequest?.portingStatus) {
      const portStatus = portingRequest?.portingStatus;

      if (invalidCancellationPortStatuses.includes(portStatus)) {
        return false;
      }
    }
    return true;
  };

  const showSendToSaleForceButton = (): boolean => {
    if (portingRequest?.portingStatus) {
      const portStatus = portingRequest?.portingStatus;

      if (invalidSendToSaleForceStatus.includes(portStatus)) {
        return false;
      }
    }
    return true;
  };

  function getBusinessDatesCount(startDate, endDate) {
    /*this function assign number to each date based on the day its occuring for
  eg sun - 0 to sat- 7 so the date we are passing is also considered in the count for eg 14-dec to 19 dec it will return 4 as the count  */
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  const canCancelORRaiseSUPRequest = (status: string): boolean => {
    if (!port.portingRequests?.[0]?.firmOrderCommitmentDate) {
      return true;
    }

    const startDate = new Date();
    const endDate = new Date(port.portingRequests?.[0]?.firmOrderCommitmentDate!);

    const numOfDates = getBusinessDatesCount(startDate, endDate);

    if (numOfDates <= 4) {
      /*we are checking here with 4 beacuse the starting date is also included in the function */
      alert.error(
        `Cannot ${status} as FOC is within 3 business days. Please contact Porting team`
      );
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    if (canCancelORRaiseSUPRequest('cancel')) {
      setShowCancelPortModal(true);
    }
  };

  const history = useHistory();
  const goToPortingHub = () => {
    history.replace(
      injectParams(ONBOARDING.portingV2, { id: currentLocation?.LocationID })
    );
  };

  const handleDelete = () => {
    if (!port.id) return;

    deletePortingData(port.id, goToPortingHub);
  };

  const cancelPhoneChanges = () => {
    setNewPhoneNumbersList(existingNumberValues);
    setNewFaxNumbersList(existingFaxValue);
    setShowPhoneModal(false);
  };

  const showSupRequestButton =
    portingRequest?.portingStatus &&
    validSUPRequestPortStatuses.includes(portingRequest?.portingStatus);

  const cancelPort = async () => {
    setShowCancelLoading(true);
    const successMsg =
      portingRequest?.portOrderNumber &&
      !portingRequest?.portOrderNumber.includes('Cancelled')
        ? 'Requested cancellation of port order'
        : 'Port order cancelled';

    try {
      // PT-TODO: migrate to schema
      await cancelPortOrder(port?.id ?? '');
      setMessagePortModal({
        body: successMsg,
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: () => {
          closeModal();
          back();
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowCancelLoading(false);
      setShowCancelPortModal(false);
      triggerModal.onClick();
    }
  };

  const handleClonePortingData = async () => {
    if (!port.id) return;

    setShowLoading(true);
    try {
      // PT-TODO: migrate to schema
      const clonedPortingData = await clonePortingData(port.id);
      setMessagePortModal({
        body: 'Port order cloned. Open cloned port order',
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: (): void => {
          closeModal();
          setPortingDetails(clonedPortingData);
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowLoading(false);
      triggerModal.onClick();
    }
  };

  const handleUploadLoaClick = (orderId: string) => {
    downloadLoa(orderId);
    const url = `https://dashboard.bandwidth.com/portal/report/#orderdetail:%7B"a":5001811,"t":"PORT_IN","o":"${orderId}"%7D`;
    window.open(url, '_blank');
  };
  const submitButtonDisplay =
    portingInfoEdit ||
    officeInfoEdit ||
    phoneNumbersEdit ||
    portingValidationData?.length > 0;

  const getPhoneNumberList = (
    chunkedPhoneNumbers: PortingRequest[][],
    numberType: NumberType,
    newPhoneNumberField: PortingRequest[]
  ) => {
    return (
      <div>
        {chunkedPhoneNumbers.map((phoneNumbers, ind) => (
          <div key={ind} className={portingCardStyles.phoneCont}>
            {phoneNumbers
              .filter((portingRequest) => portingRequest.numberType === numberType)
              .map(
                (request, index) =>
                  !phoneNumbersEdit && (
                    <Text
                      key={index}
                      css={css`
                        color: ${theme.colors.gray500};
                        margin-left: 10px;
                      `}
                    >
                      {request.phoneNumber}
                    </Text>
                  )
              )}
          </div>
        ))}
        {phoneNumbersEdit && (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;

              margin-bottom: ${theme.spacing(1)};
              margin-left: ${theme.spacing(4)};
            `}
          >
            {newPhoneNumberField?.map((existingNumberField: PortingRequest, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <PhoneField
                  {...getFieldProps('phone')}
                  value={existingNumberField?.phoneNumber || ''}
                  onChange={(value) => handleNumberChange(value, index, numberType)}
                  label="Add a phone number"
                />
                <IconButton
                  label="Remove Phone Number"
                  hoverLabelPlacement="top"
                  showLabelOnHover
                  css={css`
                    height: 24px;
                    margin-left: ${theme.spacing(1)};
                    width: 24px;
                    margin-top: ${theme.spacing(1)};
                    svg {
                      fill: #ff6b59;
                    }
                  `}
                >
                  <TrashIcon
                    color="error"
                    onClick={() => handleNumberDeletion(index, numberType)}
                  />
                </IconButton>
                {newPhoneNumberField?.length - 1 === index && (
                  <IconButton
                    label="Add Number"
                    onClick={() => handleNewNumberAddition(numberType)}
                    css={iconButtonSvgPrimary}
                    disabled={(existingNumberField?.phoneNumber?.length ?? 0) <= 3}
                  >
                    <PlusIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}
        {phoneNumbersEdit && !newPhoneNumberField.length && (
          <div
            style={{
              marginTop: `${theme.spacing(-2)}`,
              marginLeft: `${theme.spacing(6)}`,
            }}
          >
            <IconButton
              label="Add Number"
              onClick={() => handleNewNumberAddition(numberType)}
              css={iconButtonSvgPrimary}
            >
              <PlusIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const voicePhoneNumbersList = useMemo(
    () =>
      getPhoneNumberList(
        chunkedPhoneNumbers,
        NumberType.NUMBER_TYPE_PHONE,
        newPhoneNumbersList
      ),
    [chunkedPhoneNumbers]
  );
  const faxPhoneNumbersList = useMemo(
    () =>
      getPhoneNumberList(
        chunkedFaxNumbers,
        NumberType.NUMBER_TYPE_FAX,
        newFaxNumbersList
      ),
    [chunkedPhoneNumbers]
  );

  const appendPortingRequests = (
    newNumberField: PortingRequest[],
    portCopy,
    portingRequests: PortingRequest[],
    number_type: NumberType
  ) =>
    newNumberField.map((numberField) =>
      numberField === portCopy.billing_phone_number
        ? [...portingRequests].concat(portCopy.portingRequests)
        : [...portingRequests].concat(numberField)
    );

  const handlePhoneNumberUpdate = async () => {
    const portCopy = cloneDeep(port);
    const portingRequests: PortingRequest[] = [];
    const newNumberdata = appendPortingRequests(
      newPhoneNumbersList,
      portCopy,
      portingRequests,
      NumberType.NUMBER_TYPE_PHONE
    );
    const newFaxData = appendPortingRequests(
      newFaxNumbersList,
      portCopy,
      portingRequests,
      NumberType.NUMBER_TYPE_FAX
    );

    try {
      const newPortingRequests = [...newNumberdata, ...newFaxData].flat();
      const resp = await portingSchemaApi.processPortingRequestsByPhoneNumbers({
        portingRequests: newPortingRequests,
      });

      portCopy.portingRequests = resp.portingRequests;
      setPortingDetails(portCopy);
      setPhoneNumbersEdit((prevState) => !prevState);
      setDuplicateError('');
    } catch (error) {
      setDuplicateError(error?.response?.data?.error);
    }
  };

  const handleModalOpening = () => {
    const showModal = setDisableProperty();
    setShowPhoneModal(!showModal);
  };

  const updatePortDetailsByFoc = (port: PortingData) => {
    setPortingDetails(port);
  };

  const handleSUPRequestClick = () => {
    if (canCancelORRaiseSUPRequest('request SUP')) {
      try {
        console.log('HERE 1');
        getAvailableFOCDate();
        console.log('HERE 2');
      } catch (error) {
        console.log('HERE 3');
        alert.error(`Error while getting available foc dates ${error}`);
      }
      console.log('HERE 4');
      setVisible(true);
      triggerCancelProps.onClick();
    }
  };

  return (
    <Page
      title="Port Order:"
      subtitle={portingRequest?.portOrderNumber ?? ''}
      containerStyle={css({ position: 'relative' })}
    >
      {isLoadingUpdatePortingData && (
        <div className={portingCardStyles.loading}>
          <Loader />
        </div>
      )}
      <div>
        <div className={portingCardStyles.backButtonStyle} onClick={back}>
          {'< back'}
        </div>

        <div className={portingCardStyles.displayStyle}>
          <Text
            className={portingCardStyles.portingHubText}
            css={css`
              margin-bottom: ${theme.spacing(0)};
            `}
          >
            View, edit, or submit information for this port order below
          </Text>
          <div className={portingCardStyles.requestContainerButtons}>
            <div className={portingCardStyles.supRequest}>
              {showSupRequestButton && (
                <PrimaryButton size="large" color="blue" onClick={handleSUPRequestClick}>
                  SUP Request
                </PrimaryButton>
              )}
            </div>
            <div className={portingCardStyles.cancellationRequest}>
              {isDebugModeEnabled && <DeletePortingDataButton onConfirm={handleDelete} />}
              {showPortOrderCancelButton() && (
                <PrimaryButton size="large" destructive onClick={handleCancelClick}>
                  Cancellation Request
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
        <div className={portingCardStyles.errorMessage}>{error}</div>
        <div className={portingCardStyles.container}>
          <div className={portingCardStyles.backgroundStyle}>
            <div className={portingCardStyles.flexCenter}>
              <div
                css={css`
                  margin-right: 15px;
                `}
              >
                <div
                  css={css`
                    padding: ${theme.spacing(1.5)};
                    border: 1px solid ${getStatusColor(portingRequest)};
                    background-color: ${getStatusColor(portingRequest)};
                    border-radius: 50%;
                  `}
                />
              </div>
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div className={portingCardStyles.flexContainer}>
                  <div>{getStatusForPorting(port)}</div>
                  <div>
                    Requested Port Date:{' '}
                    {portingRequest?.requestedFirmOrderCommitmentDate &&
                      getRequestedPortDate()}
                  </div>
                </div>

                <div className={portingCardStyles.portOrderType}>
                  <div>Port Order # {portingRequest?.portOrderNumber}</div>
                  <div>{getPortOrderType(port)}</div>
                </div>

                <div
                  css={css`
                    text-align: right;
                    margin-top: 5px;
                  `}
                >
                  <Text>
                    Created Date:{' '}
                    {portingRequest?.createdAt &&
                      format(new Date(portingRequest?.createdAt), 'MM/dd/yy')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div
            css={css`
              padding: 25px;
            `}
          >
            <div className={portingCardStyles.portingInfoContainer}>
              <div className={portingCardStyles.title}>Porting Information</div>
              {!portingRequest?.portOrderNumber && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!portingInfoEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => handleDrawerToggle('porting', portingInfoEdit)}
                  />
                </div>
              )}
            </div>
            {portingInformationError?.map((data) => (
              <Text color="error" key={data.id}>
                {data.reason}
              </Text>
            ))}

            <div className={portingCardStyles.requestedPortDate}>
              <Text
                css={css`
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                Porting Data Id
              </Text>
              <Text
                css={css`
                  color: ${theme.colors.gray500};
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                {port.id}
              </Text>

              <div>Request Client</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {port.requestClient}
              </div>

              {!!port.requestAsapPort && (
                <>
                  <div>ASAP Port</div>
                  <FormRow
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    <CheckboxField
                      {...getFieldProps('asapPort')}
                      name="asapPort"
                      label={
                        <Text
                          css={css`
                            margin-bottom: 0;
                            color: ${theme.colors.gray500};
                          `}
                        >
                          Request ASAP Port
                        </Text>
                      }
                      disabled
                    />
                  </FormRow>
                </>
              )}

              <div
                css={css`
                  color: ${missingRequestedPort && !portingInfoEdit
                    ? theme.colors.errorPressed
                    : null};
                `}
              >
                Requested Port Date
              </div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {portingInfoEdit && (
                  <FormRow>
                    <DatePickerField
                      {...getFieldProps('requestedPortDate')}
                      name="requestedPortDate"
                      label="Port Date"
                    />
                  </FormRow>
                )}
                {portingRequest?.requestedFirmOrderCommitmentDate &&
                  !portingInfoEdit &&
                  getRequestedPortDate()}
              </div>

              {port.portingRequests?.[0]?.firmOrderCommitmentDate && (
                <>
                  <Text
                    css={css`
                      margin-bottom: ${theme.spacing(0)};
                    `}
                  >
                    Actual FOC Date
                  </Text>
                  <Text
                    css={css`
                      color: ${theme.colors.gray500};
                      margin-bottom: ${theme.spacing(0)};
                    `}
                  >
                    {port.portingRequests?.[0]?.firmOrderCommitmentDate &&
                      !portingInfoEdit &&
                      getFormattedDate(
                        port.portingRequests?.[0]?.firmOrderCommitmentDate
                      )}
                  </Text>
                </>
              )}

              <div
                css={css`
                  color: ${missingPortingCompany && !portingInfoEdit
                    ? theme.colors.errorPressed
                    : null};
                `}
              >
                Porting Company
              </div>
              {portingInfoEdit ? (
                <DropdownField
                  {...providerDropdownProps}
                  label="Provider"
                  name="provider"
                  css={css`
                    width: 200px;
                  `}
                >
                  <DropdownField.Option value={Provider.PROVIDER_UNSPECIFIED}>
                    Unspecified
                  </DropdownField.Option>
                  <DropdownField.Option value={Provider.PROVIDER_BANDWIDTH}>
                    Bandwith
                  </DropdownField.Option>
                  <DropdownField.Option value={Provider.PROVIDER_TELNYX}>
                    Telnyx
                  </DropdownField.Option>
                </DropdownField>
              ) : (
                <div
                  css={css`
                    color: ${theme.colors.gray500};
                  `}
                >
                  {port.provider}
                </div>
              )}

              <div>Porting Order URL</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                <a href={portOrderUrl} target="_blank" style={{ color: '#37CFF1' }}>
                  {portOrderUrl}
                </a>
              </div>
              <>
                <Text
                  css={css`
                    margin-bottom: ${portingInfoEdit ? '12px' : '0px'};
                  `}
                >
                  Port Type
                </Text>
                <Text
                  css={css`
                    color: ${theme.colors.gray500};
                    margin-bottom: ${portingInfoEdit ? '12px' : '0px'};
                  `}
                >
                  {port?.portType && getTypeOfPortingRequest()}
                </Text>
              </>
              {portingInfoEdit && (
                <div
                  css={css`
                    width: 200px;
                    margin: 10px auto;
                  `}
                >
                  <PrimaryButton
                    className={portingCardStyles.savePortCont}
                    color="blue"
                    size="large"
                    onClick={() => {
                      if (
                        !!portingRequest?.requestedFirmOrderCommitmentDate &&
                        (isHoliday(portingRequest?.requestedFirmOrderCommitmentDate) ||
                          isWeekend(portingRequest?.requestedFirmOrderCommitmentDate))
                      ) {
                        setPortingInfoError(
                          'Date cannot be a weekend or a major holiday.'
                        );
                      } else {
                        updatePortProvider();
                      }
                    }}
                  >
                    Save
                  </PrimaryButton>
                  {portingInfoError && (
                    <div
                      css={css`
                        margin-top: 10px;
                        color: red;
                      `}
                    >
                      {portingInfoError}
                    </div>
                  )}
                  {!!portingProviderError && (
                    <Text
                      css={css`
                        margin-top: 10px;
                      `}
                      color="error"
                    >
                      {portingProviderError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className={portingCardStyles.portingInfoContainer}>
              <div
                css={css`
                  font-weight: bold;
                  font-size: 16px;
                `}
              >
                Phone Numbers
              </div>
              {!portingRequest?.portOrderNumber && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!phoneNumbersEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => {
                      handleDrawerToggle('phone', phoneNumbersEdit);
                      handleModalOpening();
                    }}
                  />
                </div>
              )}
            </div>
            {phoneNumberError?.map((data) => (
              <Text color="error" key={data.id}>
                {data.reason}
              </Text>
            ))}
            <div className={portingCardStyles.dids}>
              <div
                css={css`
                  display: flex;
                  margin-top: 30px;
                `}
              >
                <div
                  css={css`
                    margin-bottom: ${theme.spacing(2.5)};
                    margin-right: ${theme.spacing(phoneNumbersEdit ? 3.2 : 9.2)};
                  `}
                >
                  DID(s)
                </div>
                <div>{voicePhoneNumbersList}</div>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-top: ${theme.spacing(2)};
                `}
              >
                <div
                  css={css`
                    flex: 0 0 auto;
                    margin-bottom: 10px;
                    ${phoneNumbersEdit ? 'margin-right: 0px;' : 'margin-right: 47px;'};
                  `}
                >
                  Fax DID(s)
                </div>
                <div>{faxPhoneNumbersList}</div>
              </div>
              <Text
                css={css`
                  text-align: center;
                `}
                color="error"
              >
                {duplicateError}
              </Text>
              {duplicateNumberError && (
                <Text color="error">Please remove the duplicate Number </Text>
              )}
              {phoneNumbersEdit && (
                <div className={portingCardStyles.saveOfficeCont}>
                  <PrimaryButton
                    className={portingCardStyles.saveOffice}
                    color="blue"
                    size="large"
                    onClick={handlePhoneNumberUpdate}
                    disabled={setDisableProperty()}
                  >
                    Save
                  </PrimaryButton>
                </div>
              )}
            </div>

            <PortingCardOfficeInfo
              currentPortOrder={port}
              officeInfoEdit={officeInfoEdit}
              setOfficeInfoEdit={setOfficeInfoEdit}
              handleDrawerToggle={handleDrawerToggle}
              updatePortingData={updatePortingData}
              officeInformationError={officeInformationError}
            />

            <PortingCardAttachments
              port={port}
              canEditAttachments={canEditAttachments}
              setCanEditAttachments={setCanEditAttachments}
              handleDrawerToggle={handleDrawerToggle}
              updatePortingData={updatePortingData}
              documentError={documentError}
            />

            {!portingRequest?.portOrderNumber && (
              <div className={portingCardStyles.nextStepContainer}>
                <div className={portingCardStyles.title}>Next Steps</div>
              </div>
            )}

            {!portingRequest?.portOrderNumber && (
              <div className={portingCardStyles.whatisnext}>
                <div>1. Review port information</div>
                <div>2. Submit port request</div>
              </div>
            )}

            <div className={portingCardStyles.errorText}>{error}</div>
            {portingValidationData?.length > 0 && (
              <Text color="error"> Please fix the above validation errors</Text>
            )}
            <div className={portingCardStyles.buttonContainers}>
              {!portingRequest?.portOrderNumber && (
                <>
                  <div className={portingCardStyles.portingCardButtonStyleContainer}>
                    <div className={portingCardStyles.portingCardButtonStyle}>
                      <PrimaryButton
                        className={portingCardStyles.submitButtonStyle}
                        disabled={submitButtonDisplay}
                        color="blue"
                        size="large"
                        onClick={submit}
                        css={css`
                          width: 250px;
                        `}
                      >
                        Submit
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
              {showSendToSaleForceButton() && (
                <div className={portingCardStyles.portingCardButtonStyleContainer}>
                  <div className={portingCardStyles.portingCardButtonStyle}>
                    <PrimaryButton
                      className={portingCardStyles.submitButtonStyle}
                      disabled={portingInfoEdit || officeInfoEdit || phoneNumbersEdit}
                      color="blue"
                      size="large"
                      onClick={handleSendToSalesforce}
                    >
                      Send to Salesforce
                    </PrimaryButton>
                  </div>
                </div>
              )}
              <div className={portingCardStyles.buttonContainersDownload}>
                <div
                  className={portingCardStyles.downloadLoaButton}
                  onClick={handleClonePortingData}
                >
                  Clone
                </div>
              </div>
            </div>
          </div>
        </div>

        <MessageDialogue
          header={messagePortModal.header}
          body={messagePortModal.body}
          actionLabel={messagePortModal.actionLabel}
          messageModalProps={messageModalProps}
          closeMessageModal={messagePortModal.onClose}
          onActionLabelClick={messagePortModal.onActionLabelClick}
        />

        <ContentLoader
          show={downloadLOALoading || submitPortingLoading || showLoading}
          message={downloadLOALoading ? 'Downloading...' : 'Submitting...'}
        />

        <Modal
          onClose={() => resetPortabilityCheck()}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequests.length > 1}
          className={portingCardStyles.divWidth}
        >
          <ContentLoader
            show={downloadLOALoading || submitPortingLoading || showLoading}
          />
          <div>
            <div className={portingCardStyles.lnpText}>
              Due to the complexities of porting, this port request needs to be split.
            </div>

            <div className={portingCardStyles.lnpText}>
              It will be split into {splitPortingRequests.length} porting requests.
            </div>
            <div className={portingCardStyles.loading}>
              <SecondaryButton
                className={portingCardStyles.lnpButton}
                color="gray"
                onClick={() => resetPortabilityCheck()}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                className={portingCardStyles.lnpButton}
                color="blue"
                onClick={() => submitSplitPortingRequests(port)}
              >
                Split & Submit All
              </PrimaryButton>
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => {
            resetPortabilityCheck();
            back();
          }}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequestsResults.length > 0}
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.portingResultsTitle}>Porting Results</div>
            {splitPortingRequestsResults.map((result, index) => {
              if (result?.status?.hasError) {
                return (
                  <div
                    className={`${portingCardStyles.splitError} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                    key={index}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                        color: red;
                      `}
                    >
                      Failed
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplitFail}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result?.port);
                      }}
                    >
                      View Port Order
                    </div>
                    <ul>
                      {result?.status?.errors?.map((error, index) => (
                        <li key={index}>{error.description}</li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`${portingCardStyles.splitSuccess} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                      `}
                    >
                      Success
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplit}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result?.port);
                      }}
                    >
                      View Port Order
                    </div>

                    {port.provider !== Provider.PROVIDER_TELNYX && (
                      <div
                        className={portingCardStyles.uploadLoaButtonSplit}
                        onClick={() =>
                          handleUploadLoaClick(result?.status?.orderId ?? '')
                        }
                      >
                        Upload LOA
                      </div>
                    )}
                  </div>
                );
              }
            })}

            <PrimaryButton
              className={portingCardStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                resetPortabilityCheck();
                back();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        </Modal>

        <Modal
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={showCancelPortModal}
          onClose={() => setShowCancelPortModal(false)}
        >
          {!showCancelLoading ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to cancel?
              </h1>
              <ButtonBar>
                <PrimaryButton onClick={() => cancelPort()}>Yes, cancel.</PrimaryButton>

                <SecondaryButton onClick={() => setShowCancelPortModal(false)}>
                  No, do not cancel.
                </SecondaryButton>
              </ButtonBar>
            </div>
          ) : (
            <div
              css={css`
                padding: 20px;
              `}
            >
              <div className={portingCardStyles.loading}>
                <Loader size="large" />
              </div>
              <Text
                css={css`
                  font-weight: bold;
                  font-size: ${theme.fontSize(1.5)};
                  text-align: center;
                  margin-top: 20px;
                `}
              >
                Cancelling...
              </Text>
            </div>
          )}
        </Modal>

        <Modal
          onClose={() => {
            clearSubmitPortingDetails();
            if (submitPortingDetails && !submitPortingDetails?.hasError) {
              back();
            }
          }}
          show={!!submitPortingDetails || false}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          className={portingCardStyles.divWidth}
        >
          <div>
            {submitPortingDetails && submitPortingDetails?.hasError && (
              <div>
                <div className={portingCardStyles.fixErrorReason}>
                  Porting request failed for the following reasons:
                </div>
                {submitPortingDetails?.errors?.map((error, index) => (
                  <div key={index}>{error.description}</div>
                ))}
                <div className={portingCardStyles.fixErrorTitle}>
                  Fix these errors and resubmit.
                </div>
                <PrimaryButton
                  className={portingCardStyles.errorOkayButton}
                  color="blue"
                  size="large"
                  onClick={() => clearSubmitPortingDetails()}
                >
                  Okay
                </PrimaryButton>
              </div>
            )}
            {submitPortingDetails && !submitPortingDetails?.hasError && (
              <div>
                <div className={portingCardStyles.loading}>
                  <SuccessSVG />
                </div>
                <div className={portingCardStyles.successSubmit}>
                  Port Successfully Submitted!
                </div>
                {port.provider !== Provider.PROVIDER_TELNYX && (
                  <div
                    className={portingCardStyles.uploadLoaButton}
                    onClick={() =>
                      handleUploadLoaClick(submitPortingDetails?.orderId ?? '')
                    }
                  >
                    Upload LOA
                  </div>
                )}
                <PrimaryButton
                  className={portingCardStyles.gotItButton}
                  color="blue"
                  size="large"
                  onClick={() => {
                    clearSubmitPortingDetails();
                    back();
                  }}
                >
                  Got it
                </PrimaryButton>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={showPhoneModal}
          onClose={() => setShowPhoneModal(false)}
        >
          {showPhoneModal ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to discard the changes?
              </h1>
              <ButtonBar>
                <PrimaryButton onClick={() => cancelPhoneChanges()}>Yes</PrimaryButton>

                <SecondaryButton
                  onClick={() => {
                    setShowPhoneModal(false);
                    setPhoneNumbersEdit(true);
                  }}
                >
                  No
                </SecondaryButton>
              </ButtonBar>
            </div>
          ) : (
            <div
              css={css`
                padding: ${theme.spacing(2)};
              `}
            >
              <div className={portingCardStyles.loading}>
                <Loader size="large" />
              </div>
              <Text
                css={css`
                  font-weight: ${theme.font.weight.bold};
                  font-size: ${theme.fontSize(24)};
                  text-align: center;
                  margin-top: ${theme.spacing(2)};
                `}
              >
                Cancelling...
              </Text>
            </div>
          )}
        </Modal>
        {/* {visible && minDateFoc && maxDateFoc && port.id && ( */}
        {visible && port.id && (
          <SupRequestModal
            minDateFoc={minDateFoc}
            maxDateFoc={maxDateFoc}
            cancelConfirmationModalProps={cancelConfirmationModalProps}
            closeCancelModal={closeCancelModal}
            minDate={minDate}
            focDates={focDates}
            portingDataId={port.id}
            removedDateInformation={removedDateInformation}
            port={port}
            adminEmail={username}
            updatePortDetailsByFoc={updatePortDetailsByFoc}
          />
        )}
      </div>
    </Page>
  );
};
