import { useSelector } from 'react-redux';
import { enumEquals } from 'helpers/utils';
import { VerticalEnum } from 'models/vertical-enum';
import { useModalControl } from '@weave/design-system';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LocationModel, LocationTypes } from 'redux/actions/location';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from 'models/location-feature.model';
import { selectFeatures } from 'redux/actions/customization/customization.action';
import { useFetchCustomizationsFlagByBundles } from '../customization-page.queries';
import { CustomizationFlagEditModal } from './components/customization-flag-edit-modal';
import { useWeaveVerifyPermittedACL } from 'components/weave-verify/weave-verify-utils.hooks';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import { WeaveVerifyEnableCustomizationFlagModal } from 'components/weave-verify/weave-verify-enable-customization-flag-modal';
import {
  LocationFeatureStates,
  weaveInsuranceVerificationFlag,
} from './customization-page-v2.constant';
import {
  CustomizationFlagV2ContextType,
  CustomizationFlagV2Provider,
  CustomizationFlagCountryCodeVerifiedProvider,
  CustomizationProhibitedFlagsProvider,
  CustomizationFlagBundleFlagProvider,
} from './providers';
import {
  ModalData,
  WeaveVerifyCustomizationFlagModal,
} from 'components/weave-verify/weave-verify-customization-flag-modal';
import { CustomizationPageContent } from './customization-page-content';
import { CustomizationFlagTableFilterProvider } from './providers/customization-flag-table-filter-provider';
import { isDevEnv } from '../../../config/app';
import { WamSyncStatusResponse } from 'apis/wam-sync/wam-sync.types';
import { selectFeatureFlag } from '../../../redux/actions/feature-flags';
import { WamSyncFeatureFlags } from '../customization.constant';

interface Props {
  loading: boolean;
  canEdit: boolean;
  features: LocationFeatureV2[];
  verticalId: VerticalEnum;
  location: LocationModel;
  initialFeatures: LocationFeatureV2[];
  wamSyncStatus: WamSyncStatusResponse;
  getLocationFeatures: (locationId: string) => void;
  getWamSyncStatus: (locationSlug: string) => void;
  setLocationFeatures: (
    locationId: string,
    features: LocationFeatureV2[],
    updatedFeatures: LocationFeatureV2[],
    locationSlug?: string
  ) => void;
  updateFeature: (feature: LocationFeatureV2, newState: ToggleState) => void;
  setFeatures: (features: LocationFeatureV2[]) => void;
  showError: (message: string) => void;
}

const isDev = isDevEnv();

export const CustomizationPageV2 = ({
  canEdit,
  loading,
  features,
  location,
  getLocationFeatures,
  getWamSyncStatus,
  verticalId,
  updateFeature,
  setLocationFeatures,
  initialFeatures,
  setFeatures,
  showError,
  wamSyncStatus,
}: Props) => {
  const hasWeaveVerifyACL = useWeaveVerifyPermittedACL();
  const modalData = useRef<ModalData>({} as ModalData);
  const customizationFlagsData = useSelector(selectFeatures);
  const [tableData, setTableData] = useState<LocationFeatureV2[]>(customizationFlagsData);
  const [isCustomizationFlagEdited, setIsCustomizationFlagEdited] =
    useState<boolean>(false);

  const preventSyncedEditFF = useSelector((state) =>
    selectFeatureFlag(state, WamSyncFeatureFlags.PREVENT_SYNCED_CUSTOMIZATION_CHANGE)
  );

  const showOutOfSyncAlertFF = useSelector((state) =>
    selectFeatureFlag(state, WamSyncFeatureFlags.SHOW_OUT_OF_SYNC_ALERT)
  );

  const customizationFlagEditModalProps = useModalControl();

  const featuresOutOfSyncMap = useMemo<Record<string, boolean>>(() => {
    return (wamSyncStatus?.customizationDetails || []).reduce((acc, curr) => {
      acc[curr.name] = true;
      return acc;
    }, {});
  }, [wamSyncStatus?.customizationDetails]);

  const isDemoLocation =
    location?.Type === LocationTypes.Demo || location?.Type?.toString() === 'Demo';
  const isDevLocation =
    location?.Type === LocationTypes.Dev || location?.Type?.toString() === 'Dev';

  const canEditCustomizationFlag = canEdit || isDev || isDemoLocation || isDevLocation;

  const hasOutOfSyncData = !!wamSyncStatus?.customizationDetails;

  const preventSyncedFeaturesChange =
    hasOutOfSyncData && !!preventSyncedEditFF && !(isDemoLocation || isDevLocation);

  const disableBulkEditCustomization =
    preventSyncedFeaturesChange && wamSyncStatus?.customizationDetails?.length === 0;

  const {
    modalProps: { show: showEditCustomizationFlagModal },
  } = customizationFlagEditModalProps;

  const {
    modalProps: disableInsuranceVerificationModalProps,
    openModal: openDisableInsuranceVerificationModal,
    closeModal: closeDisableInsuranceVerificationModal,
  } = useModalControl();

  const {
    modalProps: enableInsuranceVerificationModalProps,
    openModal: openEnableInsuranceVerificationModal,
    closeModal: closeEnableInsuranceVerificationModal,
  } = useModalControl();

  const {
    mutateAsync: getCustomizationsFlagsByBundles,
    isLoading: isGetCustomizationsFlagsByBundlesLoading,
  } = useFetchCustomizationsFlagByBundles();

  const refetchCustomizationFlag = () => {
    getLocationFeatures(location?.LocationID);
    if (showOutOfSyncAlertFF || preventSyncedEditFF) {
      getWamSyncStatus(location?.Slug);
    }
  };

  const saveCustomizationFlags = () => {
    if (canEditCustomizationFlag) {
      const updatedFeatures = features.filter(
        (feature, index) =>
          feature.state !== initialFeatures[index].state ||
          feature.isFreeTrial !== initialFeatures[index].isFreeTrial ||
          feature.quota !== initialFeatures[index].quota
      );
      setLocationFeatures(
        location.LocationID,
        features,
        updatedFeatures,
        // location slug is used to refetch the wam sync status and needed only if FF is turned on
        showOutOfSyncAlertFF || preventSyncedEditFF ? location.Slug : ''
      );
    }
  };

  const cancelSavingCustomizationFlags = () => {
    setFeatures(initialFeatures);
    setIsCustomizationFlagEdited(false);
  };

  const updateSelectedFeature = (
    featureToToggle: LocationFeatureV2,
    newState: ToggleState
  ) => {
    updateFeature(featureToToggle, newState);
  };

  const checkIsLocationTypeRegular = (): boolean => {
    return enumEquals(location.Type, LocationTypes.Regular, LocationTypes);
  };

  const checkIsWeaveInsuranceVerificationFlag = (
    customizationFlagName: string
  ): boolean => {
    return weaveInsuranceVerificationFlag.includes(customizationFlagName);
  };

  const getCustomizationFlagDetails = (
    currentStateLabel: CustomizationFlagStatesV2,
    newStateLabel: string
  ) => {
    const isRegularType = checkIsLocationTypeRegular();
    const isHideState =
      newStateLabel.toLowerCase() === CustomizationFlagStatesV2.HIDE &&
      currentStateLabel !== CustomizationFlagStatesV2.HIDE;
    const isFlagChanged = currentStateLabel.toLowerCase() !== newStateLabel.toLowerCase();

    return {
      isRegularType,
      isHideState,
      isFlagChanged,
    };
  };

  const handleWeaveVerifyShowStatus = (
    featureToToggle: LocationFeatureV2,
    newState: ToggleState
  ) => {
    const { isRegularType, isHideState, isFlagChanged } = getCustomizationFlagDetails(
      featureToToggle.state,
      newState.label
    );

    const isSelfSign = isRegularType && !isHideState;

    // To help the onboarding team to show subscription notes and to add a confirmation check before they turn on
    // the customization flag for a Weave Verify for the particular location.
    // Note: Only for Weave Verify
    const showSubscriptionModal =
      featureToToggle?.name === 'Weave Verify' &&
      isSelfSign &&
      hasWeaveVerifyACL &&
      isFlagChanged;

    if (showSubscriptionModal) {
      // updating the state to Active/Show if the state is promote for Weave Verify
      // Weave Verify flag will only have two state Show/Active or Hide
      const showState =
        newState.key === CustomizationFlagStatesV2.PROMOTE
          ? (LocationFeatureStates.find(
              (state) => state.key === CustomizationFlagStatesV2.ACTIVE
            ) as ToggleState)
          : newState;

      modalData.current = {
        featureToToggle,
        newState: showState,
      };
      openEnableInsuranceVerificationModal();
    } else if (isSelfSign && isFlagChanged) {
      showError(
        `Action not allowed. Must be a self sign up for Weave Insurance Verification with Customization flag ${
          featureToToggle?.name ?? ''
        }`
      );
    }
    return isSelfSign;
  };

  const handleWeaveVerifyHideStatus = (
    featureToToggle: LocationFeatureV2,
    newState: ToggleState
  ) => {
    const { isRegularType, isHideState, isFlagChanged } = getCustomizationFlagDetails(
      featureToToggle.state,
      newState.label
    );

    const isHideWeaveVerifyCustomizationFlag = isRegularType && isHideState;

    if (isHideWeaveVerifyCustomizationFlag && hasWeaveVerifyACL && isFlagChanged) {
      modalData.current = {
        featureToToggle,
        newState,
      };
      openDisableInsuranceVerificationModal();
    } else if (isHideWeaveVerifyCustomizationFlag && isFlagChanged) {
      showError(
        'Action not allowed! Only Level 2 Support and Multi office admin are allowed to perform the action'
      );
    }
    return isHideWeaveVerifyCustomizationFlag;
  };

  // Cycle to the next state in the list of possible states
  const toggleCustomizationFlag = (
    featureToToggle: LocationFeatureV2,
    newState: ToggleState
  ) => {
    if (!canEditCustomizationFlag) {
      return;
    }
    const isInsuranceVerificationFeature = checkIsWeaveInsuranceVerificationFlag(
      featureToToggle.name
    );

    // For Regular accounts, users must self sign up with for weave verify and weave verify beta
    if (
      isInsuranceVerificationFeature &&
      (handleWeaveVerifyShowStatus(featureToToggle, newState) ||
        handleWeaveVerifyHideStatus(featureToToggle, newState))
    ) {
      return;
    }
    updateFeature(featureToToggle, newState);
  };

  const updateFeatureQuota = (feature: LocationFeatureV2, quota: string) => {
    if (!canEditCustomizationFlag) {
      return;
    }
    updateFeature(feature, { key: feature.state, label: feature.state, quota: quota });
  };

  //useEffect to check whether customization flags are changed / edited
  useEffect(() => {
    const isCustomizationFlagAvailable = features.length;
    const isInitialCustomizationFlagAvailable = initialFeatures.length;

    if (isCustomizationFlagAvailable && isInitialCustomizationFlagAvailable) {
      const isCustomizationFlagChanged =
        features.filter(
          (feature, index) =>
            feature.state !== initialFeatures[index].state ||
            feature.isFreeTrial !== initialFeatures[index].isFreeTrial ||
            feature.quota !== initialFeatures[index].quota
        )?.length > 0;
      if (isCustomizationFlagChanged) {
        setIsCustomizationFlagEdited(true);
      } else {
        setIsCustomizationFlagEdited(false);
      }
    }
  }, [features, initialFeatures]);

  useEffect(() => {
    if (location?.LocationID) {
      getLocationFeatures(location?.LocationID);
    }
  }, [location?.LocationID]);

  const customizationFlagProviderValue = useMemo<CustomizationFlagV2ContextType>(() => {
    return {
      loading,
      cancelSavingCustomizationFlags,
      isCustomizationFlagEdited,
      saveCustomizationFlags,
      verticalId,
      refetchCustomizationFlag,
      customizationFlagEditModalProps,
      getCustomizationsFlagsByBundles,
      isGetCustomizationsFlagsByBundlesLoading,
      tableData,
      setTableData,
      toggleCustomizationFlag,
      canEdit: canEditCustomizationFlag,
      initialFeatures,
      updateFeatureQuota,
      featuresOutOfSyncMap,
      preventSyncedFeaturesChange,
      disableBulkEditCustomization,
    };
  }, [
    loading,
    cancelSavingCustomizationFlags,
    isCustomizationFlagEdited,
    saveCustomizationFlags,
    verticalId,
    refetchCustomizationFlag,
    customizationFlagEditModalProps,
    canEditCustomizationFlag,
    getCustomizationsFlagsByBundles,
    isGetCustomizationsFlagsByBundlesLoading,
    tableData,
    setTableData,
    toggleCustomizationFlag,
    initialFeatures,
    updateFeatureQuota,
    featuresOutOfSyncMap,
    preventSyncedFeaturesChange,
    disableBulkEditCustomization,
  ]);

  return (
    <CustomizationFlagV2Provider value={customizationFlagProviderValue}>
      <CustomizationFlagCountryCodeVerifiedProvider>
        <CustomizationProhibitedFlagsProvider>
          <CustomizationFlagTableFilterProvider>
            <CustomizationFlagBundleFlagProvider>
              <CustomizationPageContent />
              {verticalId && showEditCustomizationFlagModal && (
                <CustomizationFlagEditModal />
              )}
              {hasWeaveVerifyACL && disableInsuranceVerificationModalProps.show && (
                <WeaveVerifyCustomizationFlagModal
                  closeModal={closeDisableInsuranceVerificationModal}
                  onAccept={updateSelectedFeature}
                  modalData={modalData.current}
                  {...disableInsuranceVerificationModalProps}
                />
              )}
              {hasWeaveVerifyACL && enableInsuranceVerificationModalProps.show && (
                <WeaveVerifyEnableCustomizationFlagModal
                  closeModal={closeEnableInsuranceVerificationModal}
                  onAccept={updateSelectedFeature}
                  modalData={modalData.current}
                  {...enableInsuranceVerificationModalProps}
                />
              )}
            </CustomizationFlagBundleFlagProvider>
          </CustomizationFlagTableFilterProvider>
        </CustomizationProhibitedFlagsProvider>
      </CustomizationFlagCountryCodeVerifiedProvider>
    </CustomizationFlagV2Provider>
  );
};
