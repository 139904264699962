import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { CustomAxios, getResponseData } from 'redux/axios';
import { UseMutationResult, useMutation } from 'react-query';
import { selectCurrentLocationId } from 'redux/actions/location';
import {
  CustomizationFlagsRequest,
  CustomizationFlagsResponse,
} from './customization-page-v2/customization-page-v2.types';

const GET_CUSTOMIZATION_FLAGS_BY_BUNDLES_URL = '/package-service/v1/customizationflags';

export const customizationFlagsQueryKeys = {
  base: ['customization-flags'] as const,
  customizationFlagsByBundles: (locationId: string) => [
    ...customizationFlagsQueryKeys.base,
    'bundles',
    locationId,
  ],
};

const getQueryParamsByRequest = (request: CustomizationFlagsRequest) => {
  let queryParams = '';
  if (request.products) {
    request.products.forEach((productBundle) => {
      queryParams += `products=${productBundle}&`;
    });

    queryParams += `vertical=${request.vertical}`;
  }
  return queryParams;
};

const getCustomizationFlagsByBundles = (request: CustomizationFlagsRequest) => {
  let params = getQueryParamsByRequest(request);

  return CustomAxios.get(
    `${GET_CUSTOMIZATION_FLAGS_BY_BUNDLES_URL}?${params}`
  ).then<CustomizationFlagsResponse>(getResponseData);
};

export const useFetchCustomizationsFlagByBundles = (): UseMutationResult<
  CustomizationFlagsResponse,
  any,
  CustomizationFlagsRequest,
  any
> => {
  const locationId = useSelector(selectCurrentLocationId);
  const alert = useAlert();
  return useMutation(
    (request: CustomizationFlagsRequest) => getCustomizationFlagsByBundles(request),
    {
      mutationKey: customizationFlagsQueryKeys.customizationFlagsByBundles(locationId),
      retry: false,
      onError: () => {
        const errorMessage =
          'Something went wrong while fetching customization flag details for selected bundle';
        alert.error(errorMessage);
      },
    }
  );
};
