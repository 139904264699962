import { ModalControlResponse, useModalControl } from '@weave/design-system';
import { LocationFeatureV2 } from 'models/location-feature.model';
import { useMemo, useState } from 'react';
import {
  EditCustomizationQuotaModal,
  EditCustomizationQuotaModalProps,
} from '../components/edit-customization-quota-modal';

interface UseEditCustomizationQuotaModalResult {
  editCustomizationQuotaProps: EditCustomizationQuotaModalProps;
  openEditQuotaModal: (feature: LocationFeatureV2) => void;
  EditCustomizationQuotaModal: typeof EditCustomizationQuotaModal;
}

interface UseEditCustomizationQuotaModalProps {
  onQuotaUpdate: (feature: LocationFeatureV2, quota: string) => void;
}

export const useEditCustomizationQuotaModal = ({
  onQuotaUpdate,
}: UseEditCustomizationQuotaModalProps): UseEditCustomizationQuotaModalResult => {
  const modalControls = useModalControl();

  const [feature, setFeature] = useState<LocationFeatureV2 | null>(null);

  const openEditQuotaModal = (feature: LocationFeatureV2) => {
    setFeature(feature);
    modalControls.openModal();
  };

  const editCustomizationQuotaProps = useMemo(() => {
    return {
      feature,
      modalControls,
      onQuotaUpdate: (quota: string) => {
        feature && onQuotaUpdate(feature, quota);
      },
    };
  }, [feature, modalControls, onQuotaUpdate]);

  return {
    editCustomizationQuotaProps,
    openEditQuotaModal,
    EditCustomizationQuotaModal,
  };
};
